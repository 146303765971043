<template>
  <div class="ott-pre-loader">
    <ShowtimeLogo class="app-pre-load-logo" />
  </div>
</template>

<script>
import ShowtimeLogo from '@assets/img/__APP_TYPE__/icons/svg/showtime-logo.svg';

/**
 * App-Loading UI
 */
export default {
  name: 'AppPreLoader',
  components: {
    ShowtimeLogo,
  },
};
</script>

<style lang="scss" scoped>
  .app-pre-load-logo {
    height: auto;
    left: 50%;
    opacity: 0.15;
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 320px;
  }
</style>
