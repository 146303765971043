<template>
  <div class="promo-text">

    <img v-if="logoUrl" class="logo" :src="logoUrl">

    <div v-if="flag || flagShort" class="flag">
      <span class="mobile">{{ flagShort || flag }}</span>
      <span class="desktop">{{ flag }}</span>
    </div>

    <!-- eslint-disable vue/no-v-html -->
    <h1>
      <span class="mobile" v-html="headlineShort || headline" />
      <span class="desktop" v-html="headline" />
    </h1>

    <span v-if="bodyShort || body" class="mobile body" v-html="bodyShort || body" />
    <span v-if="body" class="desktop body" v-html="body" />
    <!-- eslint-enable vue/no-v-html -->

    <ul v-if="buttons" class="buttons">
      <li v-for="(button, index) in buttons" :key="`promo-text-button-${index}`">
        <ShoButton
          :variant="index ? 'tertiary' : 'primary'"
          :to="button.route"
          :href="button.href"
          :target="button.href ? '_blank' : null"
          :rel="button.href ? 'noopener noreferrer' : null"
          :icon="button.icon"
          @click="$emit('cta-click', button)"
        >
          {{ button.label }}
        </ShoButton>
      </li>
    </ul>

    <ShoButton
      v-if="secondaryCta"
      variant="link"
      :to="secondaryCta.route"
      :href="secondaryCta.href"
      :target="secondaryCta.href ? '_blank' : null"
    >
      {{ secondaryCta.label }}
    </ShoButton>

    <div v-if="disclaimer" class="disclaimer">{{ disclaimer }}</div>
  </div>
</template>

<script>
import ShoButton from '@components/ui/ShoButton.vue';

export default {
  name: 'PromoText',
  components: {
    ShoButton,
  },
  emits: ['cta-click'],
  props: {
    /**
     * Desktop headline
     */
    headline: {
      type: String,
      required: true,
    },
    /**
     * Mobile Headline
     */
    headlineShort: {
      type: String,
      required: false,
      default: '',
    },
    /**
     * Desktop content
     */
    body: {
      type: String,
      required: false,
      default: '',
    },
    /**
     * Mobile content
     */
    bodyShort: {
      type: String,
      required: false,
      default: '',
    },
    /**
     * 'Limited Time Offer', etc.
     */
    flag: {
      type: String,
      required: false,
      default: '',
    },
    /**
     * Mobile version of flag above
     */
    flagShort: {
      type: String,
      required: false,
      default: '',
    },
    /**
     * Small text appearing at the bottom
     */
    disclaimer: {
      type: String,
      required: false,
      default: '',
    },
    /**
     * Array of buttons
     * [{label: 'click me!', route: 'signup', icon: 'play'}]
     */
    buttons: {
      type: Array,
      required: false,
      default: null,
    },
    /**
     * Panel 'type' for styling
     */
    type: {
      type: String,
      default: null,
    },
    /**
     * Secondary text link
     */
    secondaryCta: {
      type: Object,
      default: null,
    },
    /** Optional Logo url */
    logoUrl: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.promo-text {
  margin: 16px 0;
}

.desktop {
  display: none;
}

h1 {
  @extend %ds-title-1;
  color: $ds-sho_primary;
  margin: 8px 0;
  position: relative;
}

.body {
  @extend %ds-body;
}

.flag {
  @extend %ds-callout-2;
  display: inline-block;
  padding: 2px 0;
}

.buttons {
  list-style: none;
  margin: 16px 0;
  padding: 0;
}

.buttons > li {
  display: inline-block;
  margin: 0 0 0 6px;
  width: auto;
}

.buttons > li:first-child {
  margin: 0 6px 0 0; /* Visually spaces buttons away from the primary */
}

.buttons .sho-button :deep(.sho-icon) {
  display: none;
}

.disclaimer {
  @extend %ds-caption-1;
  margin-top: 16px;
}

.logo {
  display: block;
  max-height: 50px;
  max-width: 75%;
}

/* 'Desktop' layout switch */
@media (min-width: 1024px) {
  .mobile {
    display: none;
  }

  .desktop {
    display: inline;
  }

  .buttons .sho-button :deep(.sho-icon) {
    display: inline-block;
  }
}
</style>
