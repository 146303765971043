<template>
  <ul class="social-icons" data-name="social-icons" :class="align">
    <li v-if="urls.twitter">
      <a :href="urls.twitter" target="_blank" rel="noopener noreferrer" data-app="twitter">
        <ShoIcon type="social-twitter" :height="size" :width="size" is-offset />
        <span v-if="showLabel" class="social-label">Twitter</span>
      </a>
    </li>
    <li v-if="urls.facebook">
      <a :href="urls.facebook" target="_blank" rel="noopener noreferrer" data-app="facebook">
        <ShoIcon type="social-facebook" :height="size" :width="size" is-offset />
        <span v-if="showLabel" class="social-label">Facebook</span>
      </a>
    </li>
    <li v-if="urls.instagram">
      <a :href="urls.instagram" target="_blank" rel="noopener noreferrer" data-app="instagram">
        <ShoIcon type="social-instagram" :height="size" :width="size" is-offset />
        <span v-if="showLabel" class="social-label">Instagram</span>
      </a>
    </li>
    <li v-if="urls.sho">
      <a :href="urls.sho" target="_blank" rel="noopener noreferrer" data-app="sho-com">
        <ShoIcon type="social-sho" :height="size" :width="size" is-offset />
        <span v-if="showLabel" class="social-label">Visit Sho.com</span>
      </a>
    </li>
    <li v-if="urls.store">
      <a :href="urls.store" target="_blank" rel="noopener noreferrer" data-app="sho-store">
        <ShoIcon type="social-sho-store" :height="size" :width="size" is-offset />
        <span v-if="showLabel" class="social-label">SHO Store</span>
      </a>
    </li>
  </ul>
</template>

<script>
import ShoIcon from '@components/ui/ShoIcon.vue';
export default {
  components: { ShoIcon },
  props: {
    urls : {
      type: Object,
      default  () {
        return {};
      },
      required: true,
    },
    align: {
      type: String,
      default () {
        return 'horizontal';
      },
      required: false,
    },
    showLabel: {
      type: Boolean,
      default () {
        return false;
      },
      required: true,
    },
    size: {
      type: String,
      default: '16px',
    },
  },

};
</script>

<style lang="scss" scoped>
.social-icons {
  li {
    &:first-of-type {
      margin-top: 5px;
    }
    margin-top: 8px;
  }

  &.horizontal {
    column-gap: 8px;
    display: flex;
    list-style-type: none;

    li {
      margin-top: 0;
    }
  }
}

a {
  color: $ds-sho_neutral_light;
  text-decoration: none;
}

.social-label {
  margin-left: 8px;
  position: relative;
  top: -1px;
}
</style>
