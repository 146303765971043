/* jshint esversion:6*/
/* eslint-disable camelcase */
import api from '@api/api.js';
import Logger from '@utils/logger.js';
import { findWhere, isEmpty, isLinearChannel } from '@player/utils/methods.js';
import { muxData } from '@player/config/mux.js';
import datahole from '@components/analytics/DataholeMixin.js';
import AdobeLaunchTracker from '@player/analytics/adobe-launch.js';
import { SessionStorage } from '@player/utils/storage';

import { AUTOPLAY_DEFAULTS, CLOSED_CAPTIONING_DEFAULTS, CONTENT_TYPE, STATUSES, TITLE_TYPE, CURRENT_TITLE_DEFAULTS, SKIP_DEFAULTS } from '@utils/player-constants.js';

const logger = new Logger('Player Model');
import store from '@store/store.js';

export default class PlayerModel {
  constructor (videoId = 'sho-video', dataholeData = null) {
    this.id = null;
    this.isDataLoaded = false;
    this.contentType = null;
    this.volume = {
      min: 0,
      max: 1,
      level: 0.7,
      isMute: false,
    };
    this.current = JSON.parse(JSON.stringify(CURRENT_TITLE_DEFAULTS));
    this.labelOverride = '';
    this.playback = {
      status: '',
      playhead: 0,
      duration: 0,
      error: false,
    };
    this.autoplay = JSON.parse(JSON.stringify(AUTOPLAY_DEFAULTS));
    this.skip = JSON.parse(JSON.stringify(SKIP_DEFAULTS));
    this.areYouStillWatching = {
      idleCheckTimer: null,
      idleTime: 0,
      liveInterval: null,
      nextLiveTitleTimer: null,
      userIsIdle: false,
      waitForUserActionTimer: null,
    };
    this.channel = null;
    this.previous = null;
    this.next = null;
    this.cast = {
      isCasting: false,
      deviceName: '',
    };
    this.schedulePreview = null,
    this.series = null;
    this.closedCaptioning = {
      availableTextTracks: null,
      shouldShowSettings: false,
      isEnabled: SessionStorage.getItem('ccEnabled') || false,
      activeCues: [],
      usersSettings: SessionStorage.getItem('ccSettings') || JSON.parse(JSON.stringify(CLOSED_CAPTIONING_DEFAULTS)),
    };
    this.selectedAudioTrackIndex = 0;
    this.audioTracks = null;
    this.sapAvailable = false;
    this.sapEnabled = SessionStorage.getItem('sapEnabled') ||  false;
    this.error = null;
    this.videoId = videoId;
    this.adobeLaunchTracker = new AdobeLaunchTracker();
    this.dataholeData = dataholeData;
    this.isShortForm = false;
    this.thumbnails = null;
    this.isUserScrubbing = false;
  }

  resetData () {
    this.current = JSON.parse(JSON.stringify(CURRENT_TITLE_DEFAULTS));
    this.contentType = '';
    this.channel = '';
    this.previous = null;
    this.next = null;
    this.schedulePreview = null;
    this.series = null;
    this.autoplay.countdown = 10;
    this.autoplay.userDismissed = false;
    this.autoplay.isVisible = false;
    this.isDataLoaded = false;
    if (this.closedCaptioning) {
      this.closedCaptioning.activeCues = [];
    }
    this.thumbnails = null;
    this.isUserScrubbing = false;
    this.skip = JSON.parse(JSON.stringify(SKIP_DEFAULTS));
  }

  resetClosedCaptions () {
    this.closedCaptioning.usersSettings = JSON.parse(JSON.stringify(CLOSED_CAPTIONING_DEFAULTS));
  }

  setLabelOverride (labelOverride) {
    this.labelOverride = labelOverride;
  }

  updatePlayback (playbackUpdate) {
    if (playbackUpdate.status) {
      // No need to log if the status hasn't actually changed
      if (this.playback.status !== playbackUpdate.status) {
        logger.log(`[PLAYER] [STATUS] setting status from ${this.playback.status} to ${playbackUpdate.status}`);
      }

      if (this.playback.error && playbackUpdate.status === STATUSES.playing && !playbackUpdate.error) {
        this.playback.error = false;
      }
    }
    Object.entries(playbackUpdate).forEach(([key, value]) => {
      this.playback[key] = value;
    });
  }

  incrementPlayhead () {
    if (this.playback?.playhead) {
      this.updatePlayback({ playhead: this.playback.playhead + 1 });
    }
  }

  resetPlaybackData () {
    this.updatePlayback({
      status: '',
      playhead: 0,
      duration: 0,
      error: false,
    });
    this.resetAudioTracks();
    this.closedCaptioning.availableTextTracks = null;
  }

  resetActiveCaptionsAndContentType () {
    this.closedCaptioning.activeCues = [];
    this.contentType = null;
    this.id = null;
  }

  setCurrentPlaylistType (playlistType) {
    if (this.current) {
      this.current.playlistType = playlistType;
    }
  }

  resetAudioTracks () {
    this.selectedAudioTrackIndex = 0;
    this.audioTracks = null;
  }

  loadAndSetChannelData (channel) {
    if (channel !== 'ppv') {
      const preview = store.getters['schedule/preview'];
      preview[this.channel].now.isWatching = true;
      this.schedulePreview = preview;

      const current = this.getCurrentProgram(channel) || JSON.parse(JSON.stringify(CURRENT_TITLE_DEFAULTS));
      if (current.type) {
        current.titleType = current.type;
      }
      current.playlistType = '';

      const next = this.getNextProgram(channel);

      if (this.channel === channel && (!this.current || (this.current.start !== current.start && this.current.titleId !== current.titleId))) {
        // Initial playback request, or caused by refreshing schedule data
        this.current = current;
        this.current.isLive = true;

        // update video metadata for Mux
        this.setMuxVideoData();
        window.eventBus.dispatch('media:live:new-title', this.current);

        if (next) {
          this.next = next;
        }
      }
    } else {
      this.current = {
        isLive: true,
        name: store.state.ppvState?.pages?.event?.headline || 'PPV',
        titleType: 'fight',
      };
      this.channel = 'ppv';
    }
  }

  getCurrentProgram (channel) {
    if (isEmpty(this.schedulePreview.shoeast.now) || isEmpty(this.schedulePreview.showest.now)) {
      console.error('Preview data is incomplete. Cannot find item playing now.');
      return;
    }
    const currentChannel = channel ? channel : this.getCurrentChannel();
    if (currentChannel === 'shoeast') {
      return this.schedulePreview.shoeast.now;
    } else if (currentChannel === 'showest') {
      return this.schedulePreview.showest.now;
    }
  }

  getNextProgram (channel) {
    if (isEmpty(this.schedulePreview.shoeast.next) || isEmpty(this.schedulePreview.showest.next)) {
      console.error('Preview data is incomplete. Cannot find item playing next.');
      return;
    }
    const currentChannel = channel ? channel : this.getCurrentChannel();
    if (currentChannel === 'shoeast') {
      return this.schedulePreview.shoeast.next;
    } else if (currentChannel === 'showest') {
      return this.schedulePreview.showest.next;
    }
  }

  getCurrentChannel () {
    const href = location.href;
    if (href.match(/shoeast/)) {
      return 'shoeast';
    } else if (href.match(/showest/)) {
      return 'showest';
    } else {
      return false;
    }
  }

  async checkLiveTitleSwitch (channel) {
    this.channel = this.channel ? this.channel : this.getCurrentChannel();
    const oldCurrent = JSON.parse(JSON.stringify(this.getCurrentProgram(this.channel)));

    // If there was an actual title switch, emit event and set current title model, else try again in 10 seconds
    if (this.channel === channel) {
      // Send adobe on-complete analytics calls
      this.adobeLaunchTracker.callMethod('onComplete');
      // testing to see if onComplete also ends the session
      // adobeLaunchTracker.callMethod('trackSessionEnd');
    }

    this.loadAndSetChannelData(channel);

    if (this.channel === channel) {
      this.current.refid = oldCurrent.refid;

      // Send adobe on-start analytics calls
      this.setAdobeAnalyticsData();
      this.adobeLaunchTracker.callMethod('trackSessionStart');
      this.adobeLaunchTracker.callMethod('onPlay');
    }
  }

  // Update video metadata for Mux
  setMuxVideoData () {
    const muxVideoMeta = {
      video_id: this.current.id || this.current.titleId,
      video_title:
        this.current.type !== TITLE_TYPE.episode ?
          this.current.name || this.current.title :
          `${this.current.series.seriesTitle || this.current.series.name}: ${this.current.name}`,
      video_series:
        this.current.type !== TITLE_TYPE.episode ?
          '' :
          `Season ${this.current.series.seasonNum} Episode ${this.current.series.episodeNum}`,
      video_duration: this.current.duration || this.current.runtime,
      video_stream_type: this.current.isLive ? 'live' : 'on-demand',
      video_content_type: this.current.type.toLowerCase(),
    };

    muxData.setData({ video: muxVideoMeta  });
  }

  setAdobeAnalyticsData (hasResumePoint = false) {
    const adobeVideoMeta = {
      name: (
        this.current?.type !== TITLE_TYPE.episode
          ? this.current?.name || this.current?.title
          : `${this.current?.series?.seriesTitle || this.current?.series?.name} S${this.current?.series?.seasonNum} Ep${this.current?.series?.episodeNum}`
      ) || this.labelOverride || 'no-title',


      id: this.id || this.current?.id || this.current?.titleId || 'ppv',
      length: this.current?.duration || this.current?.runtime || 0,
      streamType: this.contentType === CONTENT_TYPE.ppv ? 'live' : (this.current?.isLive ? 'linear' : 'vod'),
      hasResumePoint,
      metaData: {
        'a.media.asset': this.current?.refid || 'blank-ref-id',
        'a.media.type': this.current?.isFree ? 1 : 0,
      },
    };
    // Episode only meta data
    if (this.current?.type === TITLE_TYPE.episode) {
      adobeVideoMeta.metaData['a.media.season'] = this.current.series.seasonNum;
      adobeVideoMeta.metaData['a.media.episode'] = this.current.series.episodeNum;
      adobeVideoMeta.metaData['a.media.show'] = this.current.series.seriesTitle;
    }

    // Linear only meta data
    if (this.channel) {
      adobeVideoMeta.metaData['a.media.feed'] = this.channel;
    }

    this.adobeLaunchTracker.callMethod('createMediaObject', adobeVideoMeta);
  }

  /**
   * Loads VOD title metadata.
   * @param titleId
   */
  async loadAndSetVodData (id) {
    // check if data has already been set
    if (this.current && this.current.id && this.current.id === id) return;

    // Fetch title and series data
    try {
      const titleResponse = await api.get(`titles/${id}`);
      if (titleResponse && !titleResponse.error) {
        this.resetPlaybackData();
        this.current = titleResponse || JSON.parse(JSON.stringify(CURRENT_TITLE_DEFAULTS));
        if (this.current.type) {
          this.current.titleType = this.current.type;
        }
        this.current.isLive = false;
        this.schedulePreview = null;

        // update video metadata for Mux
        this.setMuxVideoData();

        // don't fetch episodes or user data for free episodes
        if (this.current.flags?.find((flag) => flag.toLowerCase() === 'free')) {
          this.current.isFree = true;
          return;
        }

        if (this.current.type === TITLE_TYPE.episode && this.current.series) {
          this.series = {};
          this.series.id = this.current.series.seriesId;
          const episodes = await this.getSeriesWithUsersSeries(this.series.id);
          this.series.episodes = episodes;
          this.previous = findWhere(this.series.episodes, { id: this.current.previousTitleId }) || null;
          this.next = findWhere(this.series.episodes, { id: this.current.nextTitleId }) || null;
        }
      }
    } catch (err) {
      logger.error('title call response handling error', err);
      this.updatePlayback({ error: err });
    }
  }

  /**
   * Wrapper for VOD and Live data functions.
   * @param id
   * @param type
   */
  async fetchAndSetNewTitle (id) {
    if (id !== this.current?.id) {
      this.previous = null;
      this.next = null;
    }
    this.schedulePreview = null;

    if (isLinearChannel(id)) {
      this.channel = id;
      await store.dispatch('schedule/getScheduleData', store.state.now.schedule);

      // need name of ppv fight for adobe
      await store.dispatch('ppvState/getState');

      await this.loadAndSetChannelData(id);
    } else {
      await this.loadAndSetVodData(id);
    }
    store.dispatch('appPlayer/updateCurrentTitleInfo', this.current);
  }

  canPlay (id) {
    return isLinearChannel(id) ? api.get(`channel/canplay/${id}`) : api.get(`title/canplay/title/${id}`);
  }

  async getSeriesWithUsersSeries (id) {
    const series = await api.get(`titles/series/${id}`);
    const userSeries = await api.get(`user/series/${id}`);
    if (userSeries.error) {
      return series.episodesForSeries;
    }
    return this.combineUsersEpisodesWithEpisodes(userSeries.episodes, series.episodesForSeries);
  }

  combineUsersEpisodesWithEpisodes (usersEpisodes, episodes) {
    if (usersEpisodes) {
      usersEpisodes.forEach((episode) => {
        const match = findWhere(episodes, { id: episode.titleId });
        if (match) {
          match.bookmark = episode;
        }
      });
    }

    return episodes;
  }

  /**
   * ppvStartplay - Makes startplay API call for PPV and updates model from response.
   * @param uriRequestParam
   */
  async ppvStartplay (uriRequestParam) {
    const response = await api.get(`ppv/channel/startplay/format/${uriRequestParam}/at/0`);
    if (response && !response.error) {
      this.current.refid = response.refid;
    }
    return response;
  }

  /**
   * linearStartplay - Makes startplay API call for linear channel and updates model from response.
   * @param uriRequestParam
   */
  async linearStartplay (uriRequestParam) {
    const response = await api.get(`channel/startplay/${this.id}/format/${uriRequestParam}`);
    if (response && !response.error) {
      this.current.refid = response.refid;
    }
    return response;
  }

  /**
   * vodStartplay - Makes startplay API call for a VOD title and updates model from response.
   * @param uriRequestParam
   */
  async vodStartplay (uriRequestParam) {
    const response = await api.get(`title/startplay/title/${this.id}/format/${uriRequestParam}`);

    // datahole call (trailer module)
    if (this.dataholeData) {
      datahole.methods.dhEvent({ ...this.dataholeData, event: 'trailer-module|startPlay' });
    }

    if (response && !response.error && this.current) {
      this.current.refid = response.refid;

      // Set autoplay timing data
      if (response.creditMarkerMillis) {
        this.current.creditMarkerMillis = response.creditMarkerMillis;
        this.current.creditMarkerSeconds = response.creditMarkerMillis / 1000;
      } else if (this.current.duration) {
        // default to 10 seconds if we don't receive creditMarkerMillis in startplay data
        this.current.creditMarkerSeconds = this.current.duration - 10;
        this.current.creditMarkerMillis = this.current.creditMarkerSeconds * 1000;
      }
      if (response.countdownMillis) {
        this.current.countdownMillis = response.countdownMillis;
        this.current.countdownSeconds = response.countdownMillis / 1000;
      }
    }
    return response;
  }

  played (at) {
    const routes = {
      live: `channel/played/${this.id}`,
      ppv: `ppv/channel/played/at/${at}`,
      vod: `title/played/title/${this.id}/at/${at}`,
    };
    const url = routes[this.contentType.toLowerCase()] || routes.vod;

    // datahole call (trailer module)
    if (this.dataholeData) {
      datahole.methods.dhEvent({ ...this.dataholeData, event: 'trailer-module|played', secondsWatched: at });
    }

    return api.get(url);
  }

  paused (at) {
    const routes = {
      // TODO: Is this URL right? Why do we call pause on live at all?
      live: `channel/pause/${this.id}`,
      ppv: `ppv/channel/pause/at/${at}`,
      vod: `title/pause/title/${this.id}/at/${at}`,
    };
    const url = routes[this.contentType.toLowerCase()] || routes.vod;

    // datahole call (trailer module)
    if (this.dataholeData) {
      datahole.methods.dhEvent({ ...this.dataholeData, event: 'trailer-module|pause', secondsWatched: at });
    }

    return api.get(url);
  }

  ended (at) {
    const routes = {
      live: `channel/endplay/${this.id}`,
      ppv: `ppv/channel/endplay/at/${at}`,
      vod: `title/endplay/title/${this.id}/at/${at}`,
    };
    const url = routes[this.contentType.toLowerCase()] || routes.vod;

    // datahole call (trailer module)
    if (this.dataholeData) {
      datahole.methods.dhEvent({ ...this.dataholeData, event: 'trailer-module|endPlay', secondsWatched: at });
    }

    return api.get(url);
  }

  preRollEnded (at) {
    const body = {
      creativeId: this.id,
    };
    return api.post(`adplay/preroll/end/at/${at}`, body);
  }

  preRollPaused (at) {
    const body = {
      creativeId: this.id,
    };
    return api.post(`adplay/preroll/pause/at/${at}`, body);
  }

  preRollResumed (at) {
    const body = {
      creativeId: this.id,
    };
    return api.post(`adplay/preroll/resume/at/${at}`, body);
  }

  preRollStart () {
    const body = {
      creativeId: this.id,
    };
    return api.post('adplay/preroll/start', body);
  }

  resumed (at) {
    if (this.contentType === CONTENT_TYPE.vod) {
      // datahole call (trailer module)
      if (this.dataholeData) {
        datahole.methods.dhEvent({ ...this.dataholeData, event: 'trailer-module|resume', playehad: at });
      }

      return api.get(`title/resume/title/${this.id}/at/${at}`);
    }
  }

  /**
   * Normalize audio tracks to the same object
   * This is necessary because different DRM classes provide tracks in different formats
   * @param {Array|AudioTrackList} tracks
   * @returns {Array}
   */
  normalizeAudioTracks (tracks) {
    if (!tracks) {
      return null;
    }

    /**
     * Add an appropriate label for audio tracks that might not have one
     * @param {Object} track
     * @returns {String}
     */
    const normalizeLabel = (track) => {
      if (track.label) {
        return track.label;
      }

      let label = '';
      switch (track.language) {
        case 'en': {
          label += 'English';
          break;
        }
        case 'es': {
          label += 'Spanish';
          break;
        }
        default: {
          label += 'English';
          break;
        }
      }

      switch (track.role || track.kind || track.roles?.[0]) {
        case 'alternate':
        case 'description': {
          label += ' - Audio Description';
          break;
        }
      }
      return label;
    };

    const normalizedTracks = [];

    // HLS / Fairplay return an Array-like object, so we can't .forEach here
    for (let i = 0; i < tracks.length; i++) {
      const normalizedTrack = {
        label: normalizeLabel(tracks[i]),
        language: tracks[i].language,
        role: tracks[i].role || tracks[i].kind || tracks[i].roles?.[0],
      };
      normalizedTracks.push(normalizedTrack);
    }

    return normalizedTracks;
  }

  /**
   * Receive audio track info and save it to model
   * @param {Array|AudioTrackList} - Audio track information
   */
  updateAudioTracks (tracks) {
    this.audioTracks = this.normalizeAudioTracks(tracks);
  }

  /**
   * Update select audio track index
   * @param {Number} trackIdx - Array index of newly selected audio track
   */
  updateSelectedAudioTrack (trackIdx) {
    if (trackIdx !== undefined) {
      this.selectedAudioTrackIndex = trackIdx;
    }
  }

  cleanup () {
    // no-op for now
  }
}
