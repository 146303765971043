import api from '@api/api.js';

export const url = 'user';
export const loginUrl = 'user/login';
export const logoutUrl = 'device/unpair';
export const deviceUnpairUrl = 'devices';
export const autoplayUrl = 'user/autoplay';
export const checkExistsUrl = 'user/exist';
export const createUrl = 'ott/user';
export const confirmPasswordUrl = 'user/authentication';

/* TODO: Move URLs into const object */
const URLS = {
  editInfo: 'ott/user',
  updatePassword: 'user/resetpassword',
};

// TODO: Drop down to actual necessary defaults instead of mapping the entire API
export const getDefaults = () => ({
  fetching: false,
  fetched: false,
  error: null,
  accountType: '',
  autoplay: true,
  bi: {},
  created: null,
  createdOn: null,
  emailAddress: null,
  firstName: null,
  gender: null,
  isAuthorized: false,
  lastName: null,
  legal: false,
  migrated: false,
  newsletterSubscribed: false,
  optionalAttributes: {},
  registered: false,
  tveUserId: null,
  userName: '',
  zipcode: null,
});

export const state = getDefaults();

export const getters = {
  /**
   * Indicates if a user is authenticated.
   * @param {Object} state - Vuex module state
   * @returns {boolean}
   */
  isLoggedIn (state) {
    return !!state.tveUserId;
  },
  /**
   * User's current billing type.
   * @param {Object} state - Vuex module state
   * @returns {string|null}
   */
  currentBillingType (state) {
    return state.bi && state.bi.currentBillingType || null;
  },
  /**
   * User's trial status
   * @param {Object} state - Vuex module state
   * @returns {string|null}
   */
  trialStatus (state) {
    return state.bi && state.bi.trialStatus || null;
  },
};

export const actions = {
  async flipAutoplay ({ commit }, autoplaySwitch) {
    commit('setFetching', true);
    try {
      await api.post(`${autoplayUrl}/${autoplaySwitch}`);
      commit('setAutoplay', autoplaySwitch);
    } catch (error) {
      commit('setError', error);
    }
    commit('setFetching', false);
  },

  async getUser ({ commit, state }, options = {}) {
    // skip fetch if data present
    if (state.fetched && !options.cacheBust) {
      return;
    }

    commit('setFetching', true);
    commit('setError', null);
    try {
      const response = await api.get(url);
      commit('setUser', response);
    } catch (error) {
      // TODO: handle other errors in main
      if (error.code !== 'error.session.loggedOut') {
        commit('setError', error);
      }
    }
    commit('setFetching', false);
    commit('setFetched', true);
  },

  /**
   * Sets user state from an external component.
   * @param {Object} context - Vuex module context
   * @param {Object} user - User data
   */
  setUser ({ commit }, user) {
    commit('setUser', user);
  },

  async logout ({ commit, dispatch }) {
    commit('setFetching', true);
    commit('setError', null);
    try {
      await api.get(logoutUrl);
      await dispatch('logout', null, { root: true });
      commit('resetState');
    } catch (error) {
      commit('setError', error);
    }
    commit('setFetching', false);
  },

  async unpairAllDevices () {
    try {
      await api.del(deviceUnpairUrl);
    } catch (error) {
      // intentionally empty -- this endpoint returns a session error even when it works correctly
    }
  },

  /**
   * Makes a 'create account' API request. On success, sets app user data and returns user response.
   * @param {Object} context - Vuex module context
   * @param {Object} payload - User data
   * @returns {Object}
   */
  async createAccount ({ commit }, payload) {
    // Clients need to check if an account exists before creating one
    // Moving this check to the API has been ticketed: STAT-35038
    const existResponse = await api.get(`${checkExistsUrl}/${encodeURIComponent(payload.email)}`);
    if (existResponse.exist) {
      throw {
        title: 'User Exists',
        body: existResponse.message,
        call: ['Ok'],
      };
    }

    const response = await api.post(createUrl, payload);
    commit('setUser', response);
    return response;
  },

  async login ({ commit, dispatch }, payload) {
    const response = await api.post(loginUrl, payload);
    await dispatch('login', null, { root: true });
    commit('setUser', response);
    return response;
  },

  async checkExists ({ state, commit }, emailAddress) {
    const response = await api.get(`${checkExistsUrl}/${encodeURIComponent(emailAddress)}`);
    /**
       * NOTE: This was just copying unity functionality, but I'm not sure how much I love setting
       * the active user's email address when checking if an account exists, as opposed to emitting
       * the email address along with 'exists' like the PPV check email page.
       */
    if (!state.emailAddress) {
      commit('setEmailAddress', emailAddress);
    }
    return response.exist;
  },

  setEmailAddress ({ commit }, emailAddress) {
    commit('setEmailAddress', emailAddress);
  },

  async confirmPassword ({ state }, password) {
    if (!state.emailAddress) {
      throw new Error('User not authenticated.');
    }

    await api.post(confirmPasswordUrl, {
      email: state.emailAddress,
      password,
    });
  },

  /* TODO: Rename after store split merge */
  /**
   * Updates a user's personal info and sets the response to user.
   * @param {Object} context - Vuex module context
   * @param {*} payload - Personal info data
   */
  async savePersonalInfoOTT ({ commit }, payload) {
    const response = await api.put(URLS.editInfo, payload);
    commit('setUser', response);
  },

  /**
   * Updates a user's password
   * @param {Object} context - Vuex module context
   * @param {Object} payload - Updated password payload
   * @param {string} payload.oldPassword - User's current password
   * @param {string} payload.newPassword - Updated password
   */
  async updatePassword (context, payload) {
    return api.put(URLS.updatePassword, payload);
  },

  async savePersonalInfo ({ commit, dispatch }, payload) {
    commit('setFetching', true);
    try {
      const response = await api.put(url, payload);
      // TODO: STAT-36958 - Set user object from PUT response when BE is updated
      dispatch('getUser', { cacheBust: true });
      return response;
    } catch (error) {
      commit('setFetching', false);
      throw error;
    }
  },

  async deleteAccount ({ commit, dispatch }) {
    commit('setFetching', true);
    try {
      await api.del(url);
      const response = await dispatch('logout', { cacheBust: true });
      return response;
    } catch (error) {
      commit('setError', error);
    }
  },
};

export const mutations = {
  setUser (state, user) {
    if (user.optionalAttributes && Object.prototype.hasOwnProperty.call(user.optionalAttributes, 'WEB_AUTO_PLAY') &&
      user.optionalAttributes.WEB_AUTO_PLAY === 'false') {
      // TODO: Use a getter for an 'autoplay' boolean, and set autoplay in state to match API
      user.autoplay = false;
    } else {
      user.autoplay = true;
    }
    Object.assign(state, user);
  },
  setAutoplay (state, setting) {
    state.autoplay = setting;
  },
  setFetched (state, fetched) {
    state.fetched = fetched;
  },
  setFetching (state, fetching) {
    state.fetching = fetching;
  },
  setError (state, error) {
    state.error = error;
  },
  setEmailAddress (state, emailAddress) {
    state.emailAddress = emailAddress;
  },
  resetState (state) {
    Object.assign(state, getDefaults(), { fetched: true });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
