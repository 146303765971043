<template>
  <div class="bundle-success-modal">
    <BundleSuccessContent :purchase-payload="purchasePayload" @confirm="onConfirm" />
  </div>
</template>

<script>
import BundleSuccessContent from '@components/enhanced-purchase/success/BundleSuccessContent.vue';

export default {
  name: 'BundleSuccessModal',
  components: {
    BundleSuccessContent,
  },
  props: {
    /**
     * Purchase payload including product, addons, and coupon.
     */
    purchasePayload: {
      type: Object,
      default: () => ({}),
    },
  },
  /**
   * Reset enhanced purchase session when unmounting
   * the purchase success modal.
   */
  beforeUnmount () {
    this.$store.dispatch('enhancedPurchase/reset');
  },
  methods: {
    onConfirm () {
      this.$store.dispatch('hideBundleWelcome');
    },
  },
};
</script>

<style lang="scss" scoped>
  .bundle-success-modal {
    backdrop-filter: blur(2px);
    background: rgba(0, 0, 0, 60%);
    bottom: 0;
    left: 0;
    overflow: auto;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: $z-index-app-overlay;
  }

  @media (min-width: 768px) {
    .bundle-success-modal {
      box-sizing: border-box;
      padding: 64px;
    }

    .bundle-success-content {
      margin: 0 auto;
    }
  }

  @media (min-width: 768px) and (min-height: 600px) {
    .bundle-success-modal {
      padding: 0;
    }

    .bundle-success-content {
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
</style>
