import config from '@configs/__BUILDENV__.json';

/**
 * Returns a set of default route access configs.
 * @returns {ShoRouteAccessConfig}
 */

export const getDefaultAccess = (env) => {
  // STAT defaults
  if (env === 'stat') {
    return {
      authenticated: true,
      unauthenticated: true,
      mobile: false,
    };
  }

  // OTT Closed Paywall defaults
  if (config.paywallClosed) {
    return {
      authorized: true,
      authenticated: false,
      unauthenticated: false,
      mobile: false,
    };
  }

  // OTT Open paywall defaults
  return {
    authorized: true,
    authenticated: true,
    unauthenticated: true,
    mobile: false,
  };
};
