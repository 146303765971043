/**
 * User Subscription store module. 
 * Redundant with parts of User Settings until that can be split up properly,
 * or until a sane V3 subscription/plan endpoint can be created.
 */

import api from '@api/api.js';
import { BILLING_TYPES } from '@utils/constants';

export const url = 'user/subscription';

/**
 * Defaults for the user subscription store.
 * @typedef {Object} UserSubscriptionDefaults
 * @property {boolean} fetching - Indicates if an API interaction is in progress.
 * @property {boolean} fetched - Indicates if at least one successful API call has been made.
 * @property {Object} error - Contains any API error response.
 * @property {string} billingType - User's billing type.
 * @property {boolean} eligibleToChangePlan - Indicates if a user is eligible for upgrade or downgrade.
 * @property {string} message 
 * @property {string} nextBillingDate - Next billing date UTC timestamp
 * @property {string} plan - Active plan product code.
 * @property {string} price - Price description of active plan.
 * @property {string} subscription - User's subscription status, does not match any other output format.
 * @property {Object} subscriptionStatus - User's subscription details.
 * @property {Object} trialStatus - User's trial details.
 * @property {Object[]} transactions - Array of user's billing transactions.
 */

/**
 * Returns an immutable set of default data to be used in state.
 * @returns {UserSubscriptionDefaults} - Default state data.
 */
export const getDefaults = () => ({
  fetching: false,
  fetched: false,
  error: null,
  billingType: null,
  eligibleToChangePlan: null,
  message: null,
  nextBillingDate: null,
  paymentMethod: null,
  plan: null,
  price: null,
  subscription: null,
  subscriptionStatus: {
    state: null,
    description: null,
    expirationDateMillis: 0,
  },
  trialStatus: {
    state: null,
    description: null,
    expireText: null,
    expirationDateMillis: 0,
  },
  transactions: [],
});

export const state = getDefaults();

export const getters = {
  /**
 * Returns true if direct billing user
 * @param {Object} state - Vuex module state
 * @returns {boolean}
 */
  isDirectBillingUser: (state) => {
    const billingType = state.billingType;
    return billingType === BILLING_TYPES.bacon || billingType === '';
  },

  /**
   * Returns true if complimentary account
   * @param {Object} state - Vuex module state
   * @returns {boolean}
   */
  isComplimentaryAccount (state) {
    const billingType = state.billingType;
    return billingType === BILLING_TYPES.pigBrother;
  },
};

export const mutations = {
  setFetching (state, fetching) {
    state.error = null;
    state.fetching = fetching;
  },
  setFetched (state) {
    state.fetched = true;
    state.fetching = false;
  },
  setResponse (state, response) {
    // Parse nextBillingDate if numeric
    if (response.nextBillingDate && !isNaN(Number(response.nextBillingDate))) {
      response.nextBillingDate = parseInt(response.nextBillingDate, 10);
    }

    // Reset state to defaults and then set to API response
    Object.assign(state, getDefaults(), response);
    state.fetching = false;
    state.fetched = true;
  },
  setError (state, error) {
    state.error = error;
    state.fetching = false;
  },
  reset (state) {
    Object.assign(state, getDefaults());
  },
};

export const actions = {
  /**
   * Gets user subscription details.
   * @param {Object} context - Vuex context.
   */
  async getSubscriptionDetails ({ commit, state }) {
    // Make an API call if user has requested fresh data,
    // or if data has never been fetched, and a fetch is not in progress
    if (!state.fetching) {
      try {
        commit('setFetching', true);
        const response = await api.get(url);
        commit('setResponse', response);
      } catch (error) {
        commit('setError', error);
        commit('setFetched');
      }
    }
  },
  /**
   * Resets state to defaults.
   * @param {Object} context - Vuex context
   */
  reset ({ commit }) {
    commit('reset');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
