import api from '@api/api.js';
import { ASSET_TYPES, TITLE_WATCHED_STATUSES } from '@utils/constants.js';
import Logger from '@utils/logger.js';

const logger = new Logger('resume-watching');

const resumeWatchingUrl = 'resumewatching';

export const defaults = {
  fetching: false,
  fetched: false,
  error: null,
  resumewatching: [],
};

const state = { ...defaults };

export const getters = {
  resumeWatchingShelf: (state) => (state.resumewatching ? parseResumeWatching(state.resumewatching) : null),
};

let currentFetch = null;

const actions = {
  async getResumeWatching ({ commit }) {
    commit('setFetching');
    try {
      if (currentFetch) {
        await currentFetch;
      }
      currentFetch = api.get(resumeWatchingUrl);
      const resumeWatchingTitles = await currentFetch;
      currentFetch = null;
      if (resumeWatchingTitles.error) {
        logger.error(resumeWatchingTitles.error);
      } else {
        commit('setResumeWatching', resumeWatchingTitles);
      }
    } catch (error) {
      commit('setError', error);
      logger.error(error);
    }
  },

  async deleteResumeWatchingTitle ({ commit }, id) {
    try {
      const deleteItem = await api.del(`${resumeWatchingUrl}/${id}` , `${id}`);
      if (deleteItem.error) {
        commit('setError', deleteItem.error);
        logger.error(deleteItem.error);
      } else {
        commit('deleteResumeWatchingTitle', id);
      }
    } catch (error) {
      commit('setError', error);
      logger.error(error);
    }
  },

  /**
   * Reset state to defaults
   * @param {Object} context - Store context
   */
  reset ({ commit }) {
    commit('reset');
  },
};

export const mutations = {
  setResumeWatching (state, response) {
    Object.assign(state, response);
    state.fetching = false;
    state.fetched = true;
  },
  deleteResumeWatchingTitle (state, id) {
    state.resumewatching = state.resumewatching.filter((title) => title.titleId !== id);
  },
  setFetching (state, fetching = true) {
    state.fetching = fetching;
  },
  setError (state, error) {
    state.fetching = false;
    state.fetched = false;
    state.error = error;
  },
  reset (state) {
    Object.assign(state, defaults);
  },
};

const parseResumeWatching = (resumeWatchingItems) => resumeWatchingItems.map((title) => {
  const data = title;
  if (data.images && data.images.length) {
    const hoverImage = data.images.find((i) => i.type.indexOf('MODULAR_HOMEPAGE') > -1 && i.type.indexOf('HOVER') > -1 || data.type.indexOf('MODULE_CARD_HOVER') > -1);
    data.hoverImage = hoverImage && hoverImage.url;
    const displayImage = data.images.find((i) => i.type.indexOf('MODULAR_HOMEPAGE') > -1 && i.type.indexOf('HOVER') === -1 || i.type.indexOf('MODULE_CARD_MAIN') > -1);
    data.displayImage = displayImage && displayImage.url;
  }

  if (data.type.toLowerCase() === 'non_series') {
    data.type = ASSET_TYPES.movie;
    data.name = data.heading;
  }
  if (data.type === ASSET_TYPES.series) {
    data.type = ASSET_TYPES.episode;
    data.series = { seriesId: data.seriesId, seriesTitle: data.seriesTitle, seasonNum: data.seasonNumber, episodeNum: data.episodeNumber };
    data.name = data.episodeName;
  }
  if (data.type === ASSET_TYPES.episode) {
    data.episodeName === data.name;
  }
  data.id = data.titleId;

  data.status = data.status || TITLE_WATCHED_STATUSES.inProgress;

  return {
    ...data,
  };
});

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
