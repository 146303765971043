<template>
  <component
    :is="componentType"
    v-bind="hrefAttr"
    class="sho-button"
    :class="buttonClass"
    data-name="sho-button"
    :data-route="to ? to.name : null"
    :rel="linkRelationship"
    :target="target"
    :to="to"
    :type="buttonType"
    :disabled="disabled || null"
    :icon="icon"
    @click="onClick($event)"
  >
    <ShoIcon v-if="icon" :type="icon" :is-offset="!isIconOnly" :class="{ 'icon-offset': !isIconOnly }" /><slot />
  </component>
</template>

<script>
import { Comment } from 'vue';
import ShoIcon from '@components/ui/ShoIcon.vue';

// Available style variants
export const VARIANTS = ['primary', 'ppv', 'secondary', 'tertiary', 'onboarding', 'link'];

export default {
  name: 'ShoButton',
  components: { ShoIcon },
  emits: ['click'],
  props: {
    href: {
      type: String,
      default: null,
    },
    target: {
      type: String,
      default: null,
    },
    to: {
      type: [String, Object],
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: null,
    },
    /**
     * Applies 'display: block' to the button
     */
    block: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'secondary',
      validator (value) {
        return !value || VARIANTS.includes(value);
      },
    },
  },
  computed: {
    componentType () {
      if (this.href) {
        return 'a';
      }

      if (this.to) {
        return 'router-link';
      }

      return 'button';
    },
    buttonType () {
      if (this.href || this.to) {
        return null;
      }

      return this.type || 'button';
    },
    buttonClass () {
      const classList = {
        block: this.block,
      };

      if (this.variant) {
        classList[this.variant] = true;
      }
      
      return classList;
    },
    linkRelationship () {
      return this.href && this.target === '_blank' ? 'noopener noreferrer' : null;
    },
    // This is a workaround so that empty href's don't end up on router-link
    hrefAttr () {
      if (this.href) {
        return { href: this.href };
      }
      return {};
    },
    /** returns true if slot with button text is empty */
    isIconOnly () {
      return !(this.$slots.default && this.$slots.default().findIndex((o) => o.type !== Comment) !== -1);
    },
  },
  methods: {
    onClick (e) {
      // NOTE: @click will still need to be used for now on parent components using <ShoButton>:
      // https://github.com/vuejs/vue/issues/10939 
      // Even with a dymamic directive, the '.native' modifier can't be used here to bubble events
      // for <button> or <a> tags, but it's necessary for <router-link> tags:
      // https://github.com/vuejs/vue/issues/10939#issuecomment-626677017
      this.$emit('click', e);
    },
  },
};
</script>

<style lang="scss" scoped>
.sho-button {
  @extend %ds-body-bold;
  appearance: none;
  background: rgba($ds-black, 30%);
  border: 1px solid $ds-white;
  box-sizing: border-box;
  color: $ds-white;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 15px;
  text-align: center;
  text-decoration: none;

  &:hover,
  &:focus-visible,
  &:active {
    border-color: $ds-sho_primary;
    color: $ds-sho_primary;
  }

  &:focus-visible {
    outline: 1px solid $ds-sho_primary;
  }

  &:active {
    outline: none;
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.6;

    /* automatically kills subclass :hover states, but disallows cursor styling */
    pointer-events: none;
  }

  &.block {
    display: block;
    width: 100%;
  }

  &.with-animated-input {
    height: 50px;
    line-height: 50px;
  }

  &.primary {
    background-color: $ds-sho_primary;
    border-color: $ds-sho_primary;
    color: $ds-black;

    &:hover,
    &:focus-visible,
    &:active {
      color: $ds-white;
    }

    &:focus-visible {
      outline-offset: -1px;
    }
  }

  &.ppv {
    background-color: $ds-ppv_primary;
    background-image: $ds-ppv_gradient;
    border: none;
    color: $ds-black;

    &:hover,
    &:focus-visible,
    &:active {
      background-color: rgba($ds-ppv_primary, 80%);
      background-image: none;
      color: $ds-white;
    }

    &:focus-visible {
      outline: 1px solid $ds-ppv_primary;
    }
  }

  &.tertiary {
    background: none;
    border-color: transparent;

    &:hover,
    &:focus-visible,
    &:active {
      border-color: transparent;
      color: $ds-sho_primary;
      outline: none;
    }
  }

  &.onboarding {
    background-color: $ds-black;
    border-color: $ds-black;
    color: $ds-white;

    &:hover,
    &:focus-visible,
    &:active {
      background-color: $ds-sho-primary;
      border-color: $ds-sho-primary;
    }
  }

  &.link {
    @extend %ds-body;
    background: none;
    border: none;
    color: inherit;
    cursor: pointer;
    font-family: inherit;
    font-size: inherit;
    height: auto;
    line-height: inherit;
    padding: 0;
    text-decoration: underline;
    text-transform: none;

    &:hover,
    &:focus-visible,
    &:active {
      border: none;
      opacity: 0.8;
      outline: none;
    }
  }

  .icon-offset {
    margin-right: 0.5em;
  }
}
</style>
