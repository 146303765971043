export default {
  data () {
    return {
      focusableEls: null,
      focusedElBeforeOpen: null,
      firstFocusableEl: null,
      lastFocusableEl: null,
      containerEl: null,
    };
  },
  methods: {
    /**
     * Set up focusable elements for accessibility
     * @param {HTMLElement} containerEl
     */
    initFocusTrap (containerEl) {
      this.containerEl = containerEl;
      containerEl.addEventListener('keydown', this.handleTabKey);
      this.focusedElBeforeOpen = document.activeElement;
      this.setFocusableElements();
      if (this.firstFocusableEl) {
        this.firstFocusableEl.focus();
      }
    },
    setFocusableElements () {
      if (this.containerEl) {
        const focusableEls = this.containerEl.querySelectorAll(
          'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]'
        );
        this.focusableEls = [...focusableEls];
        this.firstFocusableEl = focusableEls[0];
        this.lastFocusableEl = this.focusableEls[this.focusableEls.length - 1];
      }
    },
    /**
     * Handle Tab Key Press
     */
    handleTabKey (e) {
      if (e.key === 'Tab') {
        if (this.focusableEls.length === 1) {
          e.preventDefault();
          return;
        }

        if (e.shiftKey) {
          this.handleBackwardTab(e);
        } else {
          this.handleForwardTab(e);
        }
      }
    },
    handleForwardTab (e) {
      if (document.activeElement === this.lastFocusableEl) {
        e.preventDefault();
        this.firstFocusableEl.focus();
      }
    },
    handleBackwardTab (e) {
      if (document.activeElement === this.firstFocusableEl) {
        e.preventDefault();
        this.lastFocusableEl.focus();
      }
    },
    beforeUnmount () {
      this.deactivateFocusTrap();
    },
    deactivateFocusTrap () {
      this.containerEl.removeEventListener('keydown', this.handleTabKey);
      if (this.focusedElBeforeOpen) {
        this.focusedElBeforeOpen.focus();
      }
    },
  },
};
