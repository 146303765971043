/**
 * Loads an external javascript file and returns a Promise for success/fail.
 * @param {string} url 
 * @param {string} [id] 
 * @returns {Promise}
 */
export default function (url, id = null) {
  return new Promise((resolve, reject) => {
    const $script = document.createElement('script');
    $script.type = 'text/javascript';
    $script.onload = resolve;
    $script.onerror = reject;
    $script.id = id;
    $script.src = url;
    if (!document.getElementsByTagName('head') || !document.getElementsByTagName('head').length) {
      throw new Error('DOM head element not found.');
    }
    document.getElementsByTagName('head')[0].appendChild($script);
  });
}
