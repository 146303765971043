<template>
  <div>
    <div class="modal-backdrop" tabindex="-1" @click.self="handleCloseAttempt" />
    <Transition name="modal-anim" appear>
      <div 
        ref="modal-body"
        class="modal"
        :class="title || titleImagePath ? 'has-title' : ''"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-title"
        :aria-describedby="describedBy"
        tabindex="0"
        v-bind="$attrs"
        @keyup.esc="handleCloseAttempt"
      >
        <div v-if="title || titleImagePath" class="modal-title-container">
          <h2 v-if="title" id="modal-title" class="modal-title">{{ title }}</h2>
          <h2 v-if="titleImagePath" class="modal-title">
            <img :src="titleImagePath" class="logo img-header">
          </h2>
        </div>
        <div class="modal-body">
          <template v-if="!loading">
            <template v-if="body">
              <!-- eslint-disable-next-line vue/no-v-html -->
              <p v-html="body" />
              <div class="buttons" :class="`buttons-${parsedButtons.length}`">
                <ShoButton
                  v-for="(button, index) in parsedButtons"
                  :key="`modal-button-${index}`"
                  :variant="index === 0 ? 'primary' : 'secondary'"
                  :to="button.route || null"
                  @click="$emit('close')"
                >{{ button.label }}</ShoButton>
              </div>
            </template>
            <slot v-else />
          </template>
          <ShoLoader :loading="loading" />
        </div>
        <IconButton
          v-if="!requireConfirmation"
          class="close" 
          icon="close-button" 
          height="20px" 
          aria-label="close dialog" 
          data-name="ppv-modal-close" 
          @click="$emit('close')" 
        />
      </div>
    </Transition>
  </div>
</template>

<script>
import ShoLoader from '@components/ui/ShoLoader.vue';
import ShoButton from '@components/ui/ShoButton.vue';
import IconButton from '@components/ui/IconButton.vue';

import { setScrollLock } from '@utils/layout.js';
import FocusTrapMixin from '@components/mixins/FocusTrapMixin.js';


export default {
  name: 'ShoModal',
  components: {
    ShoLoader,
    ShoButton,
    IconButton,
  },
  inheritAttrs: false,
  mixins: [FocusTrapMixin],
  emits: ['close'],
  props: {
    /**
     * Modal title.
     */
    title: {
      type: String,
      default: null,
    },

    /**
     * Modal title image path.
     */
    titleImagePath: {
      type: String,
      default: null,
    },

    /**
     * Optional simple modal body content.
     */
    body: {
      type: String,
      default: null,
    },

    /**
     * Optional aria-describedby, ID of the element describing modal contents
     */
    describedBy: {
      type: String,
      default: null,
    },
    /**
     * Optional modal CTA buttons.
     * NOTE: These will only show up if 'body' is present; otherwise <slot> is used.
     */
    buttons: {
      type: Array,
      default: null,
    },

    /**
     * Indicates if modal content is loading.
     */
    loading: {
      default: false,
      type: Boolean,
    },
    /**
     * Indicates a user MUST interact with the CTA to close.
     * Suppresses close icon, escape presses, and closing on background clicks.
     */
    requireConfirmation: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    /**
     * Add known routes/actions to buttons based on their labels.
     * return a default 'OK' button if none are passed in
     * @returns {Array}
     */
    parsedButtons () {
      const knownButtonLabels = ['ok', 'continue', 'login', 'parentalcontrols', 'reactivate'];

      const assignButtonActions = (button) => {
        switch (button.label) {
          case 'login':
            button.route = { name: 'login' };
            break;
          case 'parentalcontrols':
            button.label = 'Viewing Restrictions';
            button.route = { name: 'settings' };
            break;
          case 'reactivate':
            button.route = { name: 'settings' };
            break;
        }

        return button;
      };

      // Only allow buttons with known routes or routes already passed in
      const buttonsArray = this.buttons ?
        this.buttons.filter((button) => button.route || knownButtonLabels.includes(button.label.toLowerCase())).map(assignButtonActions) :
        [];

      if (!buttonsArray.length) {
        return [{ label: 'ok' }];
      }

      return buttonsArray;
    },
  },

  beforeUnmount () {
    setScrollLock(false);
  },

  mounted () {
    setScrollLock(true);
    this.initFocusTrap(this.$refs['modal-body']);
  },

  methods: {
    /**
     * Catches CTA clicks emits a 'close' event.
     */
    onButtonClick () {
      this.$emit('close');
    },
    /**
     * Handles an 'esc' keypress or backdrop click.
     */
    handleCloseAttempt () {
      if (!this.requireConfirmation) {
        this.$emit('close');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  * {
    box-sizing: border-box;
  }

  .modal-backdrop {
    backdrop-filter: blur(2px);
    background-color: $ds-black;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: $z-index-app-overlay;
  }

  .modal {
    background: $ds-black;
    color: $ds-white;
    display: flex;
    flex-direction: column;
    height: 100%;
    left: 0;
    max-height: 100%;
    padding: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: $z-index-app-overlay;
  }

  .modal:focus {
    outline: none;
  }

  .modal-body {
    height: 100%;
    overflow-y: auto;
    padding: 50px;
    text-align: center;
  }

  .has-title .modal-body {
    padding: 30px;
  }

  /* Kills default top margin from <p> elements */
  .modal-body p:first-child {
    margin-top: 0;
  }

  .modal-title-container {
    align-items: center;
    border-bottom: 1px solid $ds-medium_grey;
    display: flex;
    flex: 1 0 60px;
    padding: 0 48px;
    width: 100%;
  }

  .modal-title {
    @extend %ds-title-3-bold;
    margin: 0;
    padding: 0;
    text-align: center;
    width: 100%;
  }

  .close {
    position: fixed;
    right: 20px;
    top: 15px;
  }

  :deep(.buttons) .sho-button {
    display: block;
    margin-top: 16px;
    width: 100%;
  }

  .img-header {
    height: 40px;
    padding: 5px;
    vertical-align: middle;
  }

  @media (min-width: 768px) {
    .modal-backdrop {
      background: rgba($ds-black, 50%);
      overflow-y: auto;
    }

    .modal {
      border: 1px solid $ds-medium_grey;
      box-shadow: 0 0 10px rgba(0, 0, 0, 35%);
      height: auto;
      left: 50%;
      margin: 0 auto;
      max-height: calc(100vh - 64px);
      max-width: 760px;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
    }

    .has-title .modal-body {
      height: auto;
    }

    .close {
      position: absolute;
    }

    :deep(.buttons) {
      display: flex;
    }

    :deep(.buttons) .sho-button {
      flex: 1;
      margin-right: 16px;
      white-space: nowrap;
    }

    :deep(.buttons) .sho-button:last-child {
      margin-right: 0;
    }

    .buttons.buttons-2 {
      padding: 0 16%;
    }

    :deep(.buttons.buttons-1) .sho-button {
      margin: 16px auto 0;
      max-width: 56%;
    }

    .modal-anim-enter-from,
    .modal-anim-leave-to {
      opacity: 0;
    }

    .modal-anim-enter-active,
    .modal-anim-leave-active {
      transition: opacity 500ms, transform 500ms;
    }

    .modal-anim-enter-from {
      transform: translate(-50%, -40%);
    }
  }
</style>
