<template>
  <div class="responsive-video">
    <video
      ref="video"
      :src="videoSource"
      muted
      loop
      playsinline
      autoplay
      :poster="imgSource"
    />
  </div>
</template>

<script>

export default {
  name: 'ResponsiveVideo',
  props: {
    /**
     * Object or array of objects containing {url: '', image: '', minWidth: ''}
     */
    videoData: {
      type: [Array, Object],
      required: true,
    },
  },
  data () {
    return {
      videoSource: '',
      imgSource: '',
    };
  },
  computed: {
    /**
     * Normalize the video data so that it is always an array,
     * and ensure that each item has a minWidth. Then sort the data by desc minWidth
     * @returns {Array}
     */
    sortedVideos () {
      let processedArray = this.videoData;
      if (!Array.isArray(processedArray)) {
        processedArray = [this.videoData];
      }
      return processedArray.map((video) => {
        if (!video.minWidth) {
          video.minWidth = 0;
        } 
        return video;
      }).sort((a,b) => b.minWidth - a.minWidth);
    },
  },
  mounted () {
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('scroll', this.handleScroll);
    this.handleResize();
  },
  beforeUnmount () {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    /**
     * Update video source based on window breakpoints.
     */
    handleResize () {
      const windowWidth = window.innerWidth;
      for (let i = 0; i < this.sortedVideos.length; i++) {
        if (windowWidth >= this.sortedVideos[i].minWidth) {
          if (this.videoSource !== this.sortedVideos[i].url) {
            this.imgSource = this.sortedVideos[i].image;
            this.videoSource = this.sortedVideos[i].url;
          }
          break;
        }
      }
    },
    /**
     * Play/Pause video when on/off screen.
     */
    handleScroll () {
      const bounds = this.$refs.video.getBoundingClientRect();
      if (bounds.bottom < 0) {
        this.$refs.video.pause();
      } else {
        this.$refs.video.play();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.responsive-video {
  height: 100%;
}

video {
  display: block;
  height: 100%;
  object-fit: cover;
  object-position: center bottom;
  width: 100%;
}

/* These are required to disable the controls/play button for certain ios versions */
video::-webkit-media-controls-panel {
  appearance: none;
  display: none;
}

video::-webkit-media-controls-play-button {
  appearance: none;
  display: none;
}

video::-webkit-media-controls-start-playback-button {
  appearance: none;
  display: none;
}

@media (min-width: 1024px) {
  video {
    object-position: bottom right;
  }
}
</style>
