/**
 * Sets window scroll locking.
 * @param {boolean} locked - Indicates locked or unlocked.
 */
export const setScrollLock = (locked = true) => {
  const $elements = document.querySelectorAll('body, html');
  if (!$elements || !$elements.length) {
    throw new Error('Window container not found.');
  }

  if (locked) {
    $elements.forEach(($el) => {
      $el.style.setProperty('overflow', 'hidden');
    });
  } else {
    $elements.forEach(($el) => {
      $el.style.removeProperty('overflow');
    });
  }
};

/**
 * Scroll to a CSS selector on the page, accounting
 * for fixed header height
 * @param {String} selector 
 * @param {Number} offset 
 */
export const scrollToElement = (selector, offset = 0) => {
  const headerHeight = parseInt(getComputedStyle(document.documentElement)
    .getPropertyValue('--app-header-height'), 10);
  const $el = document.querySelector(selector);
  $el && window.scrollTo({
    left: 0,
    top: $el.offsetTop - headerHeight - offset,
    behavior: 'smooth',
  });
};

/**
 * Determines if an element is in the viewport
 * @param {element} el
 * @param {number} offsetTop
 * @returns {boolean}
 */
export const isInViewport = (el, offsetTop = 0) => {
  const rect = el.getBoundingClientRect();
  return (
    rect.height &&
    rect.top >= offsetTop &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};
