<template>
  <div class="app-wrapper">
    <BundleSuccessModal v-if="bundleSuccess" :purchase-payload="bundleSuccess" />
    <Transition name="modal-fade">
      <MigrationModal v-if="shouldShowMigrationModal" v-bind="migrationModal" @close="onMigrationModalClose" />
    </Transition>
    <slot />
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';

import { STORAGE_KEYS } from '@utils/constants.js';
import ls from '@utils/local-storage.js';

import BundleSuccessModal from '@components/enhanced-purchase/success/BundleSuccessModal.vue';
import ImpactMixin from '@components/affiliates/ImpactMixin.js';
import MigrationModal from '@components/paramount-migration/MigrationModal.vue';
import PaywallMixin from '@components/paywall/PaywallMixin.js';

import Logger from '@utils/logger.js';
const logger = new Logger('AppWrapper');

export default {
  components: {
    BundleSuccessModal,
    MigrationModal,
  },
  emits: ['ready'],
  mixins: [PaywallMixin, ImpactMixin],
  computed: {
    ...mapState(['bundleSuccess', 'migrationModalVisible']),
    ...mapGetters('paywall', { paywallPage: 'activePage' }),
    ...mapState('registration', ['attributionId']),
    ...mapState('user', { isAuthorized: 'isAuthorized' }),
    ...mapGetters('user', { isUserLoggedIn: 'isLoggedIn' }),
    ...mapState('paywallAuthz', {
      migrationModal: (state) => state.pages?.paramountModal,
    }),

    /**
     * Indicates if the Migration Modal should be displayed
     */
    shouldShowMigrationModal () {
      return !!(this.migrationModalVisible && this.migrationModal);
    },
  },

  watch: {
    isAuthorized: {
      handler (isAuthorized, wasAuthorized) {
        // When a user becomes authorized, fetch paywall for migration copy
        if (isAuthorized) {
          this.$store.dispatch('paywallAuthz/fetch');
        }

        // Clear modal dismissed storage item on logout
        if (!isAuthorized && wasAuthorized) {
          sessionStorage.removeItem(STORAGE_KEYS.migrationModalDismissed);
        }
      },
      immediate: true,
    },
  },

  /**
   * Before create lifecycle hook -
   * Initializes store modules - performs any pre 'app start' checks from session storage, etc.
   * TODO: Check if this works w/ SSR, or if everything needs to be in created
   */
  beforeCreate () {
    // TODO: STAT-38115 Move localStorage state checks to awaited dispatches or inside module 'get' actions to avoid race condition issues.
    this.$store.commit('registration/initializeState');
    this.$store.commit('deviceActivation/initializeState');
    this.$store.commit('giftRedemption/initializeState');
    this.$store.commit('enhancedPurchase/initializeState');
  },
    
  /**
   * Cleanup on component unmount.
   */
  beforeUnmount () {
    window.removeEventListener('user-logout', this.onLogoutRequested);
  },

  /**
   * Created lifecycle hook
   */
  async created () {
    if ((this.$route.query.offer ||
         this.$route.query.s_cid ||
         this.$route.query.i_cid ||
         this.$route.query.o_cid ||
         this.$route.query.attr) && !this.$route.query.aff_src) {
      // If a tracking/offer CID or direct attribution are present and is NOT an affiliate link, reset affiliate cookie
      logger.log('New offer or attribution requested, clearing Impact cookie');
      this.clearImpactData();
    }

    // Init the Impact cookie logic
    this.initImpact();
    
    // Check for attribution query params
    if (this.$route.query.attr) {
      try {
        await this.$store.dispatch('registration/setAttribution', this.$route.query.attr);
      } catch (err) {
        logger.error('Could not store attribution to local storage');
      }
    }

    // Check for coupon query params
    if (this.$route.query.coupon && !this.isAuthorized) {
      try {
        await this.$store.dispatch('registration/setCoupon', this.$route.query.coupon);

        // If query param has a coupon code, and user is authenticated but not authorized send to payment page
        if (this.isUserLoggedIn) {
          this.$router.push({ name: 'payment' });
        }
      } catch (err) {
        logger.error('Could not store coupon to local storage');
      }
    }

    // Check if traffic is from sho.com to block Paywall UI test
    if (this.$route.query.r) {
      ls.setItem(STORAGE_KEYS.referrer, this.$route.query.r, 1);
    }

    // Check for Automation test settings
    if (this.$route.query['suppress-ppv'] && this.$route.query['suppress-ppv'] === 'true') {
      ls.setItem(STORAGE_KEYS.automation, { suppressPpv: true });
    } else {
      ls.removeItem(STORAGE_KEYS.automation);
    }
    
    window.addEventListener('user-logout', this.onLogoutRequested);
    this.$emit('ready');
  },
  
  methods: {
    /**
     * Dispatches user logout action
     */
    async onLogoutRequested () {
      this.$store.dispatch('startLoading');
      await this.$store.dispatch('user/logout');
      this.$store.dispatch('stopLoading');
    },
    /**
     * Handles closing the migration modal
     */
    onMigrationModalClose () {
      this.$store.dispatch('dismissMigrationModal');
    },
  },
};
</script>

<style lang="scss" scoped>
  .modal-fade-enter-active,
  .modal-fade-leave-active {
    transition: opacity 0.5s ease;
  }

  .modal-fade-enter-from,
  .modal-fade-leave-to {
    opacity: 0;
  }

  .modal-fade-enter-active :deep(.modal),
  .modal-fade-leave-active :deep(.modal) {
    transition: margin-top 0.5s ease;
  }

  .modal-fade-enter-from :deep(.modal),
  .modal-fade-leave-to :deep(.modal) {
    margin-top: 100px;
  }
</style>
