<template>
  <div class="app-drawer" ref="app-drawer">
    <ShoDrawer :open="drawerOpen" @close-click="onCloseClick">
      <div class="app-drawer-content" data-name="app-drawer">
        <UserAccountNavigation />
        <MyList v-if="isAuthorized && !isMobileDevice" />
      </div>
    </ShoDrawer>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { setScrollLock } from '@utils/layout.js';
import { isMobileDevice } from '@utils/mobile-detect.js';

import ShoDrawer from '@components/ui/ShoDrawer.vue';

import MyList from '@components/my-list/MyList.vue';
import UserAccountNavigation from '@components/menu/UserAccountNavigation.vue';
import FocusTrapMixin from '@components/mixins/FocusTrapMixin.js';

/**
 * App Drawer component - adds authorized content to the ShoDrawer UI.
 */
export default {
  name: 'AppDrawer',
  components: {
    MyList,
    ShoDrawer,
    UserAccountNavigation,
  },
  mixins: [FocusTrapMixin],
  data () {
    return {
      open: false,
    };
  },
  computed: {
    ...mapState(['drawerOpen']),
    ...mapState('user', ['isAuthorized']),
    ...mapState('myList', { myListItems: 'items' }),
    isMobileDevice () {
      return isMobileDevice();
    },
    myListItemsLength () {
      return this.myListItems.length;
    },
  },
  watch: {
    /**
     * Watches drawer open/close to trigger initial My List fetch.
     * @param {boolean} open - Drawer Open value
     */
    drawerOpen (open) {
      setScrollLock(open);
      if (open) {
        this.$nextTick(() => this.initFocusTrap(this.$refs['app-drawer']));
      } else {
        this.deactivateFocusTrap();
      }
    },
    /**
     * Update focusable elements for accessibility when mylist items are added / removed
     */
    myListItemsLength () {
      this.$nextTick(() => this.setFocusableElements());
    },
  },
  methods: {
    /**
     * Catches close-click events from Drawer UI and dispatches action.
     */
    onCloseClick () {
      this.$store.dispatch('setDrawerOpen', false);
    },
  },
};
</script>

<style lang="scss" scoped>
  .app-drawer {
    background: transparent;
    height: 100%;
    left: 0;
    pointer-events: none;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: $z-index-drawer;
  }

  .app-drawer .sho-drawer {
    pointer-events: auto;
  }

  .user-account-navigation {
    padding: 16px 24px;
  }

  .app-drawer-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
  }

  .my-list {
    overflow: auto;
    padding: 16px 24px;
  }
</style>
