import api from '@api/api.js';

const MIGRATE_URL = 'user/migrate';
const FAQ_URL = 'migration/faq';

const actions = {
  migrate () {
    return api.put(MIGRATE_URL);
  },
  getFaq () {
    return api.get(FAQ_URL);
  },
};

export default {
  namespaced: true,
  actions,
};
