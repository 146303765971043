<template>
  <footer>
    <div class="footer-inner-container">

      <div v-if="isOTT" class="footer-section" :class="{expanded: menus.help.expanded}">
        <h2 class="footer-section-label" @click="toggleExpand('help')">Help</h2>
        <button 
          id="help-menu-button" 
          class="footer-section-label mobile" 
          aria-haspopup="true" 
          aria-controls="help-menu" 
          :aria-expanded="menus.help.expanded" 
          @click="toggleExpand('help')"
        >
          Help
        </button>
        <ul id="help-menu" role="menu" aria-labelledby="help-menu-button">
          <li role="menuitem"><a :href="dictionary.customerService.helpCenter" target="_blank" rel="noopener noreferrer">Help Center</a></li>
          <li role="menuitem"><a href="https://showtime.zendesk.com/hc/en-us/articles/206396958-What-devices-can-I-use-to-watch" target="_blank" rel="noopener noreferrer">Supported Devices</a></li>
          <li><a href="https://showtime.zendesk.com/hc/en-us/articles/226369967-How-can-I-contact-SHOWTIME-Customer-Service-" target="_blank" rel="noopener noreferrer">Contact Us</a></li>
        </ul>
      </div>

      <div v-if="isSTAT" class="footer-section" :class="{expanded: menus.about.expanded}">
        <h2 class="footer-section-label" @click="toggleExpand('about')">About</h2>
        <button 
          id="about-menu-button" 
          class="footer-section-label mobile" 
          aria-haspopup="true" 
          aria-controls="about-menu" 
          :aria-expanded="menus.about.expanded" 
          @click="toggleExpand('about')"
        >
          About
        </button>
        <ul id="about-menu" role="menu" aria-labelledby="about-menu-button">
          <li role="menuitem"><router-link :to="{ name: 'about' }">About Showtime Anytime</router-link></li>
          <template v-if="!isLoggedIn">
            <li role="menuitem"><ShoButton class="link" @click="onStatLoginClick">Providers</ShoButton></li>
          </template>
          <li role="menuitem"><a :href="dictionary.customerService.helpCenter" target="_blank" rel="noopener noreferrer">Help Center</a></li>
          <li role="menuitem"><a href="https://showtime.zendesk.com/hc/en-us/articles/226369967-How-can-I-contact-SHOWTIME-Customer-Service-" target="_blank" rel="noopener noreferrer">Contact Us</a></li>
        </ul>
      </div>

      <div v-if="isOTT" class="footer-section" :class="{expanded: menus.gifts.expanded}">
        <h2 class="footer-section-label" @click="toggleExpand('gifts')">Gifts</h2>
        <button 
          id="gifts-menu-button" 
          class="footer-section-label mobile" 
          aria-haspopup="true" 
          aria-controls="gifts-menu" 
          :aria-expanded="menus.gifts.expanded" 
          @click="toggleExpand('gifts')"
        >
          Gifts
        </button>
        <ul id="gifts-menu" role="menu" aria-labelledby="gifts-menu-button">
          <li role="menuitem"><router-link :to="{ name: 'giftRedemption', }">Redeem Gift</router-link></li>
        </ul>
      </div>

      <div v-if="isSTAT" class="footer-section" :class="{expanded: menus.devices.expanded}">
        <h2 class="footer-section-label" @click="toggleExpand('devices')">Devices</h2>
        <button 
          id="devices-menu-button" 
          class="footer-section-label mobile" 
          aria-haspopup="true" 
          aria-controls="devices-menu" 
          :aria-expanded="menus.devices.expanded" 
          @click="toggleExpand('devices')"
        >
          Devices
        </button>
        <ul id="devices-menu" role="menu" aria-labelledby="devices-menu-button">
          <li role="menuitem"><ShoButton class="link" @click="onActivateClick">Activate a Device</ShoButton></li>
        </ul>
      </div>

      <div v-if="isSTAT && !isMobileDevice && !isLoggedIn" class="footer-section" :class="{expanded: menus.sample.expanded}">
        <h2 class="footer-section-label" @click="toggleExpand('sample')">Sample Showtime</h2>
        <button 
          id="sample-menu-button" 
          class="footer-section-label mobile" 
          aria-haspopup="true" 
          aria-controls="sample-menu" 
          :aria-expanded="menus.sample.expanded" 
          @click="toggleExpand('sample')"
        >
          Sample Showtime
        </button>
        <ul id="sample-menu" role="menu" aria-labelledby="sample-menu-button">
          <li role="menuitem"><router-link :to="{name: 'freeFullEpisodes'}">Free Full Episodes</router-link></li>
          <li role="menuitem"><router-link :to="{name: 'liveTV'}">Live TV Schedule</router-link></li>
        </ul>
      </div>

      <div class="footer-section" :class="{expanded: menus.terms.expanded}">
        <h2 class="footer-section-label" @click="toggleExpand('terms')">Legal</h2>
        <button 
          id="legal-menu-button" 
          class="footer-section-label mobile" 
          aria-haspopup="true" 
          aria-controls="legal-menu" 
          :aria-expanded="menus.terms.expanded" 
          @click="toggleExpand('terms')"
        >
          Legal
        </button>
        <ul id="legal-menu" role="menu" aria-labelledby="legal-menu-button">
          <li role="menuitem" v-for="(link, index) in legalLinks" :key="index">
            <a :href="link.url" target="_blank">
              {{ link.label }}
            </a>
          </li>
          <li role="menuitem"><a class="ot-sdk-show-settings">Your Privacy Choices</a></li>
          <li role="menuitem"><a href="https://privacy.paramount.com/en/policy#additional-information-us-states" target="_blank" rel="noopener noreferrer">California Notice</a></li>
          <li role="menuitem"><a href="https://showtime.zendesk.com/hc/en-us/articles/220753107-Who-should-I-contact-if-I-have-questions-or-concerns-about-closed-captioning-on-SHOWTIME-" target="_blank" rel="noopener noreferrer">Closed Captioning</a></li>
        </ul>
      </div>

      <div class="footer-section" :class="{expanded: menus.more.expanded}">
        <h2 class="footer-section-label" @click="toggleExpand('more')">More Showtime</h2>
        <button 
          id="more-menu-button" 
          class="footer-section-label mobile" 
          aria-haspopup="true" 
          aria-controls="more-menu" 
          :aria-expanded="menus.more.expanded" 
          @click="toggleExpand('more')"
        >
          More Showtime
        </button>
        <SocialIcons id="more-menu" role="menu" aria-labelledby="more-menu-buttom" :urls="socialURLS" :align="'vertical'" :show-label="true" />
      </div>

      <div class="footer-section legal-text" :class="!isLoggedIn && isOTT ? 'full-width' : ''">
        <!-- eslint-disable vue/no-v-html -->
        <p v-if="paywallLegalInfo"><span v-html="paywallLegalInfo" /></p>
        <p v-if="isLoggedIn"><span v-html="dictionary.legal.footer" /></p>
        <template v-if="!isLoggedIn">
          <p v-if="shouldDisplayTrialDisclaimer"><span v-html="dictionary.disclaimer" /></p>
          <p v-if="dictionary.legal.footer"><span v-html="dictionary.legal.footer" /></p>
          <p v-if="dictionary.accessibility"><span v-html="dictionary.accessibility" /></p>
        </template>
        <!-- eslint-enable vue/no-v-html -->
      </div>

    </div>
    <div class="red-bar" />
  </footer>
</template>

<script>
import ShoButton from '@components/ui/ShoButton.vue';
import SocialIcons from '@components/social-icons/SocialIcons.vue';
import { mapGetters, mapState } from 'vuex';
import { isMobileDevice } from '@utils/mobile-detect.js';

import { APP_TYPES } from '@utils/constants.js';
import Logger from '@utils/logger.js';

const logger = new Logger('AppFooter');

// TODO: Name this something other than 'Footer' to avoid conflicts

export default {
  components: {
    ShoButton,
    SocialIcons,
  },
  data () {
    return {
      menus: {
        about: {
          expanded: false,
        },
        devices: {
          expanded: false,
        },
        gifts: {
          expanded: false,
        },
        help: {
          expanded: false,
        },
        more: {
          expanded: false,
        },
        sample: {
          expanded: false,
        },
        terms: {
          expanded: false,
        },
      },
    };
  },
  computed: {
    ...mapState(['dictionary']),
    ...mapState('user', ['isAuthorized']),
    ...mapGetters('user', ['isLoggedIn']),
    ...mapGetters('paywall', ['activePage']),

    isOTT () {
      return __APP_TYPE__ === APP_TYPES.ott;
    },

    isSTAT () {
      return __APP_TYPE__ === APP_TYPES.stat;
    },

    isMobileDevice,

    socialURLS () {
      const urls = {
        twitter: this.dictionary.social.twitter,
        facebook: this.dictionary.social.facebook,
        instagram: this.dictionary.social.instagram,
        sho: 'https://www.sho.com?s_cid=da-default-4027',
        store: 'https://store.sho.com/?utm_source=showtime&utm_medium=referral&utm_campaign=footer',
      };
      return urls;
    },
    /**
     * Parse dictionary's legal page urls.
     */
    legalLinks () {
      const hrefs = this.dictionary.legal && this.dictionary.legal.links || {};
      const labels = this.dictionary.legal && this.dictionary.legal.meta || {};
      const links = [];
      for (const index in hrefs) {
        // Filter out 'Do Not Sell My Info...' legal link in favor of the OneTrust link
        if (labels[index]?.linkText.toLowerCase().indexOf('do not sell') > -1) {
          continue;
        }
        if (labels[index]?.linkText) {
          links.push({ url: hrefs[index], label: labels[index].linkText });
        } else {
          logger.warn(`Missing legal link title for '${index}'`);
        }
      }
      return links;
    },
    /**
     * Check if the activePage from paywall has additional legal copy to prepend.
     */
    paywallLegalInfo () {
      if (this.activePage?.legalFooter) {
        return this.activePage.legalFooter;
      }
      return null;
    },
    /**
     * Indicates if the free trial legal disclaimer should be displayed.
     * Based on user state and paywall configs.
     * @returns {boolean}
     */
    shouldDisplayTrialDisclaimer () {
      return !!(!this.activePage?.suppressTrialDisclaimer && this.dictionary?.disclaimer);
    },
  },
  methods: {
    toggleExpand (menuName) {
      this.menus[menuName].expanded = !this.menus[menuName].expanded;
    },
    onActivateClick () {
      this.$store.dispatch('deviceActivation/reset');
      this.$store.dispatch('statActivation/show');
    },
    /**
     * Handles STAT footer login clicks.
     */
    onStatLoginClick () {
      this.$store.dispatch('msoPicker/show');
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

footer {
  background-color: $ds-black;
  border-top: 1px solid rgba($ds-medium_grey, 40%);
  color: $ds-sho_neutral_light;
  position: relative;
}

.red-bar {
  background-color: $ds-sho_primary;
  height: 4px;
  margin: 0 var(--shelf-container-margin);
}

.footer-inner-container {
  max-width: 1600px;
  min-width: 320px;
  padding-bottom: 40px;
}

.footer-section {
  @extend %ds-caption-2;
  border-bottom: 1px solid rgba($ds-medium_grey, 40%);
  color: $ds-sho_neutral_light;
  padding: 24px var(--shelf-container-margin);

  &.expanded {
    .footer-section-label::after {
      transform: rotate(90deg);
    }

    ul {
      display: block;
    }

    .social-icons {
      margin-top: 16px;
    }
  }

  &:last-of-type {
    border-bottom: none;
  }

  a,
  button.link {
    color: $ds-sho_neutral_light;
    cursor: pointer;
    opacity: 1;
    text-decoration: none;
  }

  ul {
    display: none;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin-top: 20px;
    }
  }
}

.footer-section-label {
  @extend %ds-caption-2;
  color: $ds-white;
  display: none;
  font-weight: bold;
  margin: 0;
  position: relative;
  text-transform: uppercase;

  &.mobile {
    appearance: none;
    background: none;
    border: none;
    display: block;
    padding: 0;
    text-align: left;
    width: 100%;
  }

  &::after {
    background: url("~@assets/img/icons/svg/chevron-right.svg?inline") no-repeat;
    background-size: contain;
    content: "";
    height: 16px;
    position: absolute;
    right: 0;
    transform: rotate(0);
    transition: transform ease-out 0.1s 0s;
    width: 16px;
  }
}

.legal-text p {
  margin-top: 0;
}

@media all and (min-width: 768px) {
  footer {
    z-index: 1;
  }

  .red-bar {
    height: 6px;
  }

  .footer-section-label {
    display: block;

    &::after {
      display: none;
    }

    &.mobile {
      display: none;
    }
  }

  .footer-inner-container {
    column-gap: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto;
    padding: 40px var(--shelf-container-margin);
  }

  .footer-section {
    border-bottom: none;
    flex: 1;
    padding: 0;

    &.full-width {
      flex: auto;
      padding-top: 20px;
      width: 100%;
    }

    ul {
      display: block;

      li {
        margin-top: 8px;
      }
    }
  }

  .legal-text {
    flex: 2;
  }
}
</style>
