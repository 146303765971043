<template>
  <div class="notification">
    <!-- eslint-disable vue/no-v-html -->
    <p class="notification-body" @click.prevent="openLink" v-html="body" />
    <ShoIcon type="close-button" @click="closeNotification" />
  </div>
</template>
<script>
import ShoIcon from '@components/ui/ShoIcon.vue';
/**
 * @displayName TOSNotification
 */
export default {
  components: {
    ShoIcon,
  },
  emits: ['close'],
  /**
   * Notification text to display
   */
  props: {
    body: {
      type: String,
      required: true,
    },
  },
  mounted () {
    this.setNotificationTimeout();
  },
  methods: {
    /**
     * Closes the Terms of Service notification box.
     * Triggered by either clicking the close button,
     * or after it has displayed for 30 seconds.
     */
    closeNotification () {
      this.$emit('close');
    },
    /**
     * Intercepts link clicks within the Terms of Service notification box
     * and opens them in new window.
     */
    openLink (e) {
      if (e.target.href) {
        window.open(e.target.href);
      }
    },

    /**
     * Sets a timer and calls {@link closeNotification}
     * method after 30 seconds.
     */
    setNotificationTimeout () {
      setTimeout(this.closeNotification, 30000);
    },
  },
};
</script>
<style lang="scss" scoped>
.notification {
  @extend %ds-caption-1;
  background: rgba(19, 14, 14, 90%);
  bottom: 0;
  box-sizing: border-box;
  left: 0;
  padding: 15px 45px 15px 15px;
  position: fixed;
  text-align: center;
  width: 100%;
  z-index: $z-index-app-overlay;
}

.notification-body :deep(a) {
  text-decoration: none;
}

.notification .sho-icon {
  cursor: pointer;
  height: 20px;
  margin-top: -10px;
  position: absolute;
  right: 15px;
  top: 50%;
}
</style>
