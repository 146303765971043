import { createStore } from 'vuex';

import appPlayer from '@store/modules/app-player.js';
import titleDetail from '@store/modules/title-detail.js';
import dictionary from '@store/modules/dictionary.js';
import user from '@store/modules/__APP_TYPE__/user.js';
import userTitle from '@store/modules/user-title.js';
import paywall from '@store/modules/paywall.js';
import paywallAuthz from '@store/modules/paywall-authz.js';
import search from '@store/modules/search.js';
import movies from '@store/modules/movies.js';
import series from '@store/modules/series.js';
import category from '@store/modules/category.js';
import promos from '@store/modules/promos.js';
import curatedLists from '@store/modules/curated-lists.js';
import curatedListDetail from '@store/modules/curated-list-detail.js';
import newsletters from '@store/modules/ott/newsletters.js';
import seriesDetail from '@store/modules/series-detail.js';
import seriesSeasons from '@store/modules/series-seasons.js';
import viewerRestrictions from '@store/modules/viewer-restrictions.js';
import navigation from '@store/modules/navigation.js';
import userDownloads from '@store/modules/user-downloads.js';
import homeModules from '@store/modules/home-modules.js';
import cancelAccount from '@store/modules/cancel-account.js';
import resumeWatching from '@store/modules/resume-watching.js';
import now from '@store/modules/now.js';
import schedule from '@store/modules/schedule.js';
import userSeries from '@store/modules/user-series.js';
import plan from '@store/modules/plan.js';
import availableBundles from '@store/modules/available-bundles.js';
import coupon from '@store/modules/coupon.js';
import previewPlans from '@store/modules/preview-plans.js';
import baconSubscription from '@store/modules/bacon-subscription.js';
import userSubscription from '@store/modules/user-subscription.js';
import myList from '@store/modules/my-list.js';
import settingsNotification from '@store/modules/settings-notification.js';
import freeFullEpisodes from '@store/modules/free-full-episodes.js';
import deviceActivation from '@store/modules/device-activation.js';
import modal from '@store/modules/modal.js';
import partner from '@store/modules/partner.js';
import password from '@store/modules/password.js';
import giftRedemption from '@store/modules/gift-redemption.js';
import complimentaryAccount from '@store/modules/complimentary-account.js';
import cancellationOffer from '@store/modules/cancellation-offer.js';
import analytics from '@store/modules/analytics.js';
import registration from '@store/modules/registration.js';
import enhancedPurchase from '@store/modules/enhanced-purchase.js';
import availablePlanIntervals from '@store/modules/available-plan-intervals.js';
import userMigration from '@store/modules/user-migration.js';

// PPV Modules
import ppvState from '@store/modules/ppv/ppv-state.js'; // Event State
import ppvEvent from '@store/modules/ppv/ppv-event.js'; // Event Info
import ppvSchedule from '@store/modules/ppv/ppv-schedule.js'; // Press Schedule
import userPPV from '@store/modules/ppv/user-ppv.js'; // PPV Purchase History
import ppvPurchase from '@store/modules/ppv/ppv-purchase.js'; // Event Purchase

// Form Stores
import confirmPassword from '@store/modules/forms/confirm-password.js';
import editBilling from '@store/modules/forms/edit-billing.js';

// STAT Stores
import mso from '@store/modules/stat/mso.js';
import msoPicker from '@store/modules/stat/mso-picker.js';
import statNewsletters from '@store/modules/stat/newsletters.js';
import statRegistration from '@store/modules/stat/stat-registration.js';
import statActivation from '@store/modules/stat/stat-activation.js';
import upsellModal from '@store/modules/stat/upsell-modal.js';

import { STORAGE_KEYS } from '@utils/constants.js';

/**
 * Actions to call on auth change to reset user-state data.
 */
const resetActions = [
  'userSubscription/reset',
  'plan/reset',
  'availableBundles/reset',
  'baconSubscription/reset',
  'userTitle/reset',
  'myList/reset',
  'resumeWatching/reset',
  'userPPV/reset',
  'ppvState/reset',
  'viewerRestrictions/reset',
  'settingsNotification/dismissNotification',
];

// NOTE: Should this get broken out into an app module, or a layout module? Or should top level be assumed app?

const mutations = {
  incrementLoadingCount (state) {
    state.loadingCount++;
  },
  decrementLoadingCount (state, options = {}) {
    if (options.stopAll) {
      state.loadingCount = 0;
    } else if (state.loadingCount > 0) {
      state.loadingCount--;
    }
  },
  resetLoadingCount (state) {
    state.loadingCount = 0;
  },
  setDrawerOpen (state, open) {
    state.drawerOpen = open;
  },
  setFooterHidden (state, hidden = false) {
    state.footerHidden = hidden;
  },
  setBundleSuccess (state, payload) {
    state.bundleSuccess = payload;
  },
  setMigrationModalVisible (state, visible = true) {
    state.migrationModalVisible = visible;
  },
};

const actions = {
  /**
   * Increments loading count
   * @param {Object} context - Vuex context
   */
  startLoading ({ commit }) {
    commit('incrementLoadingCount');
  },
  /**
   * Reset user-related stores before user fetch on login.
  */
  async login ({ dispatch }) {
    await Promise.all(resetActions.map((action) => dispatch(action, null, { root: true })));
  },
  /**
   * Reset user-related stores before on logout.
   * Async to ensure actions finish before resetting user state, because that triggers a
   * redirect to front door, which could cause a new fetch on some of these stores.
  */
  async logout ({ dispatch }) {
    await Promise.all([...resetActions, 'paywall/reset', 'paywallAuthz/reset'].map((action) => dispatch(action, null, { root: true })));
  },
  /**
   * Decrements loading count
   * @param {Object} context - Vuex context
   * @param {Object} [options] - Optional action parameters
   * @param {boolean} [options.stopAll] - Forces a reset of the entire loading stack
   */
  stopLoading ({ commit }, options) {
    commit('decrementLoadingCount', options);
  },
  /**
   * Resets loading count
   * @param {Object} context - Vuex context
   */
  killLoading ({ commit }) {
    commit('resetLoadingCount');
  },
  /**
   * Sets the My List drawer as open or closed.
   * @param {Object} context - Vuex context
   * @param {boolean} open - Indicates if the drawer is open.
   */
  setDrawerOpen ({ commit }, open = true) {
    commit('setDrawerOpen', open);
  },
  /**
   * Hides the app footer
   * @param {Object} context - Vuex context
   */
  hideFooter ({ commit }) {
    commit('setFooterHidden', true);
  },
  /**
   * Shows the app footer
   * @param {Object} context - Vuex context
   */
  showFooter ({ commit }) {
    commit('setFooterHidden', false);
  },
  /**
   * Displays the bundle purchase success modal.
   * @param {Object} context - Vuex context
   */
  displayBundleWelcome ({ commit }, payload) {
    commit('setBundleSuccess', payload);
  },
  /**
   * Hides the bundle purchase success modal.
   * @param {Object} context - Vuex context
   */
  hideBundleWelcome ({ commit }) {
    commit('setBundleSuccess', null);
  },
  /**
   * Handles a request to display the migration modal
   * @param {Object} conext 
   */
  requestMigrationModal ({ commit }, options = {}) {
    const dismissed = sessionStorage.getItem(STORAGE_KEYS.migrationModalDismissed);
    if (!dismissed || options.force) {
      commit('setMigrationModalVisible', true);
      sessionStorage.removeItem(STORAGE_KEYS.migrationModalDismissed);
    }
  },
  dismissMigrationModal ({ commit }) {
    sessionStorage.setItem(STORAGE_KEYS.migrationModalDismissed, true);
    commit('setMigrationModalVisible', false);
  },
};

export const getters = {
  /**
   * Boolean getter representing loading count
   * @param {Object} state - Current store module state
   * @returns {boolean}
   */
  loading (state) {
    return !!state.loadingCount;
  },
};

export default createStore({
  state: {
    bundleSuccess: null,
    footerHidden: false,
    drawerOpen: false,
    loadingCount: 0,
    migrationModalRequested: false,
    migrationModalDismissed: false,
    migrationModalVisible: false,
  },
  actions,
  mutations,
  getters,
  modules: {
    appPlayer,
    dictionary,
    titleDetail,
    user,
    userTitle,
    paywall,
    paywallAuthz,
    confirmPassword,
    search,
    movies,
    series,
    category,
    promos,
    curatedLists,
    curatedListDetail,
    newsletters,
    seriesDetail,
    viewerRestrictions,
    seriesSeasons,
    navigation,
    userDownloads,
    editBilling,
    homeModules,
    cancelAccount,
    resumeWatching,
    now,
    schedule,
    userSeries,
    plan,
    availableBundles,
    coupon,
    previewPlans,
    baconSubscription,
    userSubscription,
    userPPV,
    myList,
    settingsNotification,
    freeFullEpisodes,
    ppvState,
    deviceActivation,
    ppvEvent,
    ppvSchedule,
    modal,
    partner,
    password,
    ppvPurchase,
    giftRedemption,
    complimentaryAccount,
    mso,
    cancellationOffer,
    msoPicker,
    analytics,
    statNewsletters,
    statRegistration,
    statActivation,
    registration,
    upsellModal,
    enhancedPurchase,
    availablePlanIntervals,
    userMigration,
  },
});
