<template>
  <div class="user-account-navigation">
    <h1>Account</h1>
    <ul>
      <li>
        <router-link :to="{ name: 'settings' }">Settings</router-link>
      </li>
      <li v-if="isSTAT">
        <button class="link" data-name="app-drawer:activate-button" @click="onActivateClick">Activate a Device</button>
      </li>
      <li>
        <a :href="customerService.helpCenter" target="_blank" rel="noopener noreferrer">Help Center</a>
      </li>
      <li>
        <button data-name="app-drawer:sign-out-button" aria-label="sign out" @click.prevent="onLogoutClick">Sign Out</button>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { APP_TYPES } from '@utils/constants.js';

export default {
  name: 'UserAccountNavigation',
  computed: {
    ...mapState('dictionary', { customerService: 'customerService' }),
    /**
     * Indicates if the app is running in STAT mode.
     * @returns {boolean}
     */
    isSTAT () {
      return __APP_TYPE__ === APP_TYPES.stat;
    },
  },
  methods: {
    /**
     * Handles logout click. Triggers app loader and calls logout store method.
     */
    async onLogoutClick () {
      window.dispatchEvent(new CustomEvent('user-logout'));
    },
    /**
     * Handles 'Activate a Device' button clicks, and initiates activation.
     */
    onActivateClick () {
      this.$store.dispatch('deviceActivation/reset');
      this.$store.dispatch('statActivation/show');
      this.$store.dispatch('setDrawerOpen', false);
    },
  },
};
</script>

<style lang="scss" scoped>
  h1 {
    @extend %ds-headline;
    margin: 0 0 12px;
  }

  ul {
    @extend %ds-body;
    border-top: 1px solid $ds-medium_grey;
    list-style: none;
    margin: 0;
    padding: 16px 0 0;
  }

  li {
    padding: 0 0 4px;
  }

  button {
    appearance: none;
    background: none;
    border: none;
    font-size: inherit;
    line-height: inherit;
    padding: 0;
    text-align: left;
    text-transform: inherit;
    width: 100%;
  }

  a,
  button {
    color: $ds-sho_neutral_light;
    display: block;
    text-decoration: none;
    transition: color 200ms;
  }

  a:focus,
  a:hover,
  button:focus,
  button:hover {
    color: white;
    outline: none;
  }
</style>
