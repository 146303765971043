/* eslint-disable camelcase */
import api from '@api/api.js';
import Logger from '@utils/logger.js';
import { url as baconSubscriptionUrl } from '@store/modules/bacon-subscription.js';
import { RECURLY_PAYMENT_METHODS } from '@utils/constants.js';

const logger = new Logger('edit-billing');

const validPaymentMethods = [RECURLY_PAYMENT_METHODS.card, RECURLY_PAYMENT_METHODS.paypal];
export const getDefaults = () => ({
  loading: false,
  success: false,
  errors: {
    form: null,
  },
  recurlyToken: null,
  paymentMethod: null,
  isPaypalValid: false,
  // Recurly-shared fields
  first_name: null,
  last_name: null,
  postal_code: null,
});

export const state = { ...getDefaults() };

export const validateCC = function (data) {
  const errors = {};

  if (!data.recurlyToken) {
    logger.error('Recurly token missing from edit billing request.');
    return { form: 'There was an issue validating your information, please try again.' };
  }

  if (!data.first_name || !data.first_name.trim().length) {
    errors.first_name = 'Your first name is required.';
  }

  if (!data.last_name || !data.last_name.trim().length) {
    errors.last_name = 'Your first name is required.';
  }

  return errors;
};

export const validatePaypalContinue = function (data) {
  const errors = {};

  if (!data.postal_code || !data.postal_code.trim()) {
    errors.postal_code = 'A valid zip code is required.';
  }

  return errors;
};

export const validatePaypal = function (data) {
  const errors = validatePaypalContinue(data);

  if (!data.recurlyToken) {
    logger.error('Recurly token missing from edit billing request.');
    return { form: 'There was an issue validating your information, please try again.' };
  }

  return errors;
};

export const actions = {
  updateUserBilling ({ state, commit }) {
    // TODO: Validate password
    commit('clearErrors');

    if (!state.paymentMethod || validPaymentMethods.indexOf(state.paymentMethod) < 0) {
      commit('setErrors', { form: 'There was an issue validating your information, please try again.' });
      return;
    }

    const errors = state.paymentMethod === RECURLY_PAYMENT_METHODS.card ? validateCC(state) : validatePaypal(state);

    if (Object.entries(errors).length) {
      commit('setErrors', errors);
      return;
    }
    
    commit('setLoading');
    return api.put(baconSubscriptionUrl, {
      firstName: state.first_name,
      lastName: state.last_name,
      zipcode: state.postal_code,
      recurlyToken: state.recurlyToken,
    })
      .then((response) => {
        commit('baconSubscription/setResponse', response, { root: true });
        commit('setSuccess', true);
      })
      .catch((error) => {
        logger.error('Error updating billing.', error);
        commit('setErrors', {
          form: error.body,
        });
      });
  },
  validatePaypalContinue ({ state, commit }) {
    commit('clearErrors');
    const errors = validatePaypalContinue(state);

    if (Object.entries(errors).length) {
      commit('setErrors', errors);
      return;
    }

    commit('setPaypalValid', true);
  },
  setPaymentMethod ({ commit }, paymentMethod) {
    commit('resetForm');
    commit('setPaymentMethod', paymentMethod);
  },
  setFields ({ commit }, data) {
    commit('updateFields', data);
  },
  setErrors ({ commit }, errors) {
    commit('setErrors', errors);
  },
  clearErrors ({ commit }) {
    commit('clearErrors');
  },
  setPaypalValid ({ commit }) {
    commit('setPaypalValid', false);
  },
  resetForm ({ commit }) {
    commit('resetForm');
  },
};

export const mutations = {
  setPaymentMethod (state, paymentMethod) {
    state.paymentMethod = paymentMethod;
  },
  setRecurlyToken (state, recurlyToken) {
    state.recurlyToken = recurlyToken;
  },
  updateFields (state, data) {
    // Whitelist fields?
    Object.assign(state, {
      ...state,
      ...data,
    });
  },
  setErrors (state, errors) {
    state.errors = {
      ...state.errors,
      ...errors,
    };
    state.loading = false;
  },
  clearErrors (state) {
    state.errors = {
      form: null,
    };
  },
  resetForm (state) {
    Object.assign(state, getDefaults());
  },
  setPaypalValid (state, valid) {
    state.isPaypalValid = valid;
  },
  setSuccess (state, success) {
    state.success = success;
    state.loading = false;
  },
  setLoading (state, loading = true) {
    state.loading = loading;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
