<template>
  <div 
    class="migration-modal modal-container" 
    @keyup.esc="closeModal"
    data-name="migration-modal"
    :data-site-section="siteSection"
    :data-sub-sections="subSections"
  >
    <div class="modal">
      <IconButton 
        class="close-btn" 
        icon="close-button" 
        height="20px" 
        aria-label="close dialog" 
        data-name="ppv-modal-close" 
        @click="closeModal"
      />
      <div class="img-vid-container">
        <ResponsiveVideo v-if="videoData" :video-data="videoData" />
        <picture v-else>
          <source :srcset="imageUrl" media="(min-width: 680px)">
          <img :src="mobileImageUrl">
        </picture>
      </div>
      <div ref="modal-body" class="body">
        <img v-if="logoUrl" :src="logoUrl" class="logo" alt="Showtime/Paramount+ logo">
        <PromoText 
          v-bind="promoTextProps"
          @cta-click="onCtaClick"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { PAYWALL_IMAGE_TYPES } from '@utils/constants.js';
import IconButton from '@components/ui/IconButton.vue';
import PromoText from '@components/ui/PromoText.vue';
import ResponsiveVideo from '@components/responsive-video/ResponsiveVideo.vue';

import FocusTrapMixin from '@components/mixins/FocusTrapMixin.js';
import { setScrollLock } from '@utils/layout.js';

export default {
  name: 'MigrationModal',
  mixins: [FocusTrapMixin],
  emits: ['close'],
  components: {
    IconButton,
    PromoText,
    ResponsiveVideo,
  },
  props: {
    logoUrl: {
      type: String,
      default: null,
    },
    headline: {
      type: String,
      required: true,
    },
    headlineShort: {
      type: String,
      default: null,
    },
    body: {
      type: String,
      default: null,
    },
    bodyShort: {
      type: String,
      default: null,
    },
    flag: {
      type: String,
      default: null,
    },
    flagShort: {
      type: String,
      default: null,
    },
    buttons: {
      type: Array,
      default: null,
    },
    images: {
      type: Array,
      default: null,
    },
    videoData: {
      type: Object,
      default: null,
    },
    biMetadata: {
      type: Object,
      default: (() => {}),
    },
  },
  computed: {
    /**
     * Returns a subset of props that get passed on to PromoText
     * @returns {Object}
     */
    promoTextProps () {
      return {
        headline: this.headline,
        headlineShort: this.headlineShort,
        body: this.body,
        bodyShort: this.bodyShort,
        flag: this.flag,
        flagShort: this.flagShort,
        buttons: this.buttons,
      };
    },
    /**
     * Default hero image URL.
     * @returns {(string|null)}
     */
    imageUrl () {
      if (!this.images) {
        return null;
      }

      const image = this.images.find((img) => img.type === PAYWALL_IMAGE_TYPES.hero);
      return image?.url;
    },
    /**
     * Responsive hero image URL.
     * @returns {(string|null)}
     */
    mobileImageUrl () {
      if (!this.images) {
        return null;
      }

      const image = this.images.find((img) => img.type === PAYWALL_IMAGE_TYPES.heroMobile);
      return image?.url;
    },
    siteSection () {
      return this.biMetadata?.siteSection;
    },
    subSections () {
      return this.biMetadata?.subSections && this.biMetadata.subSections.join(':');
    },
  },
  mounted () {
    setScrollLock();
    this.initFocusTrap(this.$refs['modal-body']);
  },
  unmounted () {
    setScrollLock(false);
  },
  methods: {
    closeModal () {
      this.$emit('close');
    },
    /**
     * Handles CTA clicks, closes modal for internal actions/links.
     * @param {Object} button 
     */
    onCtaClick (button) {
      if (!button.href) {
        this.$emit('close');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .modal-container {
    background: rgba(0, 0, 0, 50%);
    bottom: 0;
    box-sizing: border-box;
    left: 0;
    overflow-y: auto;
    padding: 24px;
    position: fixed;
    right: 0;
    top: 0;
    z-index: $z-index-app-overlay;
  }

  .modal {
    background: $ds-black;
    border: 1px solid $ds-dark_grey;
    border-radius: 5px;
    box-sizing: border-box;
    min-height: 150vw;
    padding-top: 100vw;
    position: relative;
    width: 100%;
  }

  .logo {
    display: block;
    height: 32px;
    margin: 0 0 16px;
    position: relative;
  }

  .close-btn {
    position: absolute;
    right: 8px;
    top: 24px;
    transform: translateY(-50%);
    z-index: $z-index-app-overlay;
  }

  .img-vid-container {
    aspect-ratio: 2 / 3;
    border-radius: 5px;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .img-vid-container > *::before {
    background: linear-gradient(0deg, rgba(0, 0, 0, 100%) 0%, rgba(0, 0, 0, 0%) 100%);
    bottom: 0;
    content: "";
    height: 50%;
    position: absolute;
    width: 100%;
    z-index: 0;
  }

  .img-vid-container img,
  .img-vid-container :deep(video) {
    border-radius: 5px;
  }

  .img-vid-container img {
    display: block;
    width: 100%;
  }

  .body {
    padding: 0 16px 16px;
  }

  .promo-text {
    margin: 0;
    padding: 0;
    position: relative;
  }

  /* Type style overrides */
  .promo-text :deep(h1) {
    @extend %ds-title-2;
    color: $ds-white;
    margin-bottom: 16px;
  }

  .promo-text :deep(.flag) {
    color: $ds-sho_primary;
  }

  .promo-text :deep(.sho-button.primary) {
    background: $ds-white;
    border: none;
    color: $ds-black;
  }

  .promo-text :deep(.sho-button.primary:hover),
  .promo-text :deep(.sho-button.primary:focus),
  .promo-text :deep(.sho-button.primary:focus-visible) {
    border-color: $ds-xtra_light_grey;
    color: $ds-dark_grey;
    outline: none;
  }

  @media (min-width: 680px) {
    .modal-container {
      align-items: center;
      display: flex;
      justify-content: center;
    }

    .modal {
      border: none;
      margin: 0 auto;
      max-width: 1024px;
      min-height: 0;
      padding: 0;
    }

    .body {
      padding: 0 0 0 40px;
    }

    .close-btn {
      right: 16px;
      top: 32px;
    }

    .logo {
      left: 24px;
      position: absolute;
      top: 24px;
    }

    .img-vid-container {
      aspect-ratio: 16 / 9;
      height: auto;
      position: relative;
    }

    .img-vid-container > *::before {
      background: linear-gradient(90deg, rgba(0, 0, 0, 100%) 0%, rgba(0, 0, 0, 0%) 66%);
      border-radius: 5px;
      height: 100%;
      left: 1px;
      top: 1px;
      width: 100%;
    }

    .img-vid-container img,
    .img-vid-container :deep(video) {
      border: 1px solid $ds-dark_grey;
    }

    .promo-text {
      margin-top: 0;
      position: absolute;
      top: 50%;
      transform: translate(0, -40%);
      width: 70%;
    }
  }

  @media (min-width: 1024px) {
    .promo-text {
      width: 50%;
    }
  }
</style>
