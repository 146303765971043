import api from '@api/api.js';

export const newslettersUrl = 'user/newsletters';

export const getDefaults = () => ({
  subscriptions: [],
  error: null,
  fetching: false,
  fetched: false,
});

const state = getDefaults();

// TODO: Is a getter to rename a single attribute necessary?
export const getters = {
  newsletters: (state) => (state.subscriptions ? state.subscriptions : null),
};

// TODO: Add a reset action for logging out
export const actions = {
  async getNewsletterSubscriptions ({ commit }) {
    commit('setFetching', true);
    try {
      const response = await api.get(newslettersUrl);
      commit('setNewsletters', response);
      commit('setFetched', true);
    } catch (error) {
      commit('setError', error);
    }
    commit('setFetching', false);
  },

  async updateNewsletterSubscription ({ commit, state }, subUpdate) {
    let payload = {};
    payload = Object.assign(state, payload);
    // If check allows update action to be more flexable and handle un-subbing all in one action or one at a time.
    if (subUpdate.unsubscribeAll) {
      payload.subscriptions.forEach((sub) => {
        sub.subscribed = false;
      });
    } else {
      payload.subscriptions.find((s) => s.id === subUpdate.id).subscribed = subUpdate.value;
    }
    try {
      await api.put(newslettersUrl, payload.subscriptions);
      commit('updateSubscription', payload.subscriptions);
    } catch (error) {
      commit('setError', error);
    }
  },
};

export const mutations = {
  setNewsletters (state, newsletters) {
    Object.assign(state, newsletters);
  },
  updateSubscription (state, payload) {
    Object.assign(state.subscriptions, payload);
  },
  setError (state, error) {
    state.error = error;
  },
  setFetching (state, fetching) {
    state.fetching = fetching;
  },
  setFetched (state, fetched) {
    state.fetched = fetched;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
