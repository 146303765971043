import api from '@api/api.js';
import Logger from '@utils/logger.js';
const logger = new Logger('user-title');

const url = 'user/title';

export const defaultValues = {
  bookmark: {
    inMyList: false,
    longTimeRemaining: null,
    mediumTimeRemaining: null,
    percentWatched: null,
    seconds: null,
    shortTimeRemaining: null,
    status: null,
  },
  isBookmarked: false,
  isInMyList: false,
};

// TODO: Flatten this
const state = { userTitle: { ...defaultValues } };

let currentFetch = null;

// TODO: Handle API Errors
const actions = {
  async getUserTitleInfo ({ commit }, titleId) {
    try {
      if (currentFetch) {
        await currentFetch;
      }
      currentFetch = api.get(`${url}/${titleId}`);
      const response = await currentFetch;
      currentFetch = null;
      commit('setUserTitle', { ...defaultValues, ...response });
    } catch (error) {
      logger.error(error);
    }
  },

  /**
   * Reset state to defaults
   * @param {Object} context - Store context
   */
  reset ({ commit }) {
    commit('reset');
  },
};

export const mutations = {
  setUserTitle (state, response) {
    state.userTitle = {
      ...defaultValues,
      ...response,
    };
  },
  reset (state) {
    Object.assign(state, { userTitle: { ...defaultValues } });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
