const EnhancedCreateAccountPage = () => import('@pages/enhanced-purchase/EnhancedCreateAccountPage.vue');
const EnhancedLoginPage = () => import('@pages/enhanced-purchase/EnhancedLoginPage.vue');
const EnhancedPlanSelectPage = () => import('@pages/enhanced-purchase/EnhancedPlanSelectPage.vue');
const EnhancedPaymentPage = () => import('@pages/enhanced-purchase/EnhancedPaymentPage.vue');

import store from '@store/store.js';
import config from '@configs/__BUILDENV__.json';

/**
 * Checks app config for bundle route accessibility.
 * @param {*} to 
 * @param {*} from 
 * @param {*} next 
 */
function beforeEnter (to, from, next) {
  if (!config.bundleEnabled) {
    next({ name: 'frontDoor' });
  } else {
    next();
  }
}

const routes = [
  /**
   * Bundle purchase init route. Redirects based on user state and sets new purchase type.
   */
  {
    path: '/shhtopsecret-purchase/bundle',
    name: 'purchaseBundle',
    beforeEnter (to, from, next) {
      const isLoggedIn = store.getters['user/isLoggedIn'];
      if (store.state.user.isAuthorized) {
        next({ name: 'home', query: to.query });
      } else if (isLoggedIn) {
        next({ name: 'purchasePlan', query: to.query  });
      } else {
        next({ name: 'purchaseSignup', query: to.query });
      }
    },
  },
  /**
   * Enhanced Purchase signup route.
   */
  {
    path: '/purchase/account/:email?',
    name: 'purchaseSignup',
    component: EnhancedCreateAccountPage,
    props: true,
    beforeEnter,
    meta: {
      section: 'enhanced-purchase',
      /* @type {ShoRouteAccessConfig} */
      access: {
        ott: {
          authorized: false,
          authenticated: false,
          unauthenticated: true,
          mobile: true,
        },
      },
    },
  },
  /**
   * Enhanced Purchase login route.
   */
  {
    path: '/purchase/login',
    name: 'purchaseLogin',
    component: EnhancedLoginPage,
    props: true,
    beforeEnter,
    meta: {
      section: 'enhanced-purchase',
      /* @type {ShoRouteAccessConfig} */
      access: {
        ott: {
          authorized: false,
          authenticated: false,
          unauthenticated: true,
          mobile: true,
        },
      },
    },
  },
  /**
   * Enhanced Purchase plan select step.
   */
  {
    path: '/purchase/plan',
    name: 'purchasePlan',
    component: EnhancedPlanSelectPage,
    props: true,
    beforeEnter,
    meta: {
      section: 'enhanced-purchase',
      /* @type {ShoRouteAccessConfig} */
      access: {
        ott: {
          authorized: false,
          authenticated: true,
          unauthenticated: false,
          mobile: true,
        },
      },
    },
  },
  /**
   * Enhanced Purchase payment step.
   */
  {
    path: '/purchase/payment',
    name: 'purchasePayment',
    component: EnhancedPaymentPage,
    props: true,
    beforeEnter,
    meta: {
      section: 'enhanced-purchase',
      /* @type {ShoRouteAccessConfig} */
      access: {
        ott: {
          authorized: false,
          authenticated: true,
          unauthenticated: false,
          mobile: true,
        },
      },
    },
  },
];

export default routes;
