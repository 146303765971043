import AppMain from '@src/AppMain.vue';
import { createApp } from 'vue';
import store from '@store/store.js';
import Logger from '@utils/logger.js';
import { router } from '@src/router.js';
import config from '@configs/__BUILDENV__.json';
import api from '@api/api.js';
import { APP_TYPES } from '@utils/constants.js';
import Optimizely from '@optimizely/optimizely-sdk';

// Temp config to disable sunset message check
const doAppStatusCheck = true;

export default {
  /**
   * Displays a generic error message on any pre-fetch error.
   */
  showAppError () {
    const container = document.getElementById('website-2000');
    const helpLink = (__APP_TYPE__ === APP_TYPES.ott) ? 'https://help.showtime.com/hc/en-us' : 'https://help.showtimeanytime.com/hc/en-us';
    const errorTemplate = `
      <div style="background: black; color: white; text-align: center; font-family: Arial, Helvetica, sans-serif; padding: 20px; box-sizing: border-box; width: 90%; max-width: 600px; border: 1px solid white; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%);">
        <h1>SERVER ERROR</h1>
        <p>Looks like something went wrong. Please try again.</p>
        <p>If the problem persists please contact our <a href="${helpLink}">help center</a>.</p>
      </div>
    `;

    if (container) {
      container.innerHTML = errorTemplate;
    } else {
      this.logger.error('Container div not found.');
    }
  },

  /**
   * Checks if the app is still alive.
   * If the request returns a 404, the app is still active, otherwise an object of images is returned.
   */
  async getAppActiveStatus () {
    // Quick hack to disable the app status check in 3.2.8
    if (!doAppStatusCheck) {
      return { active: true, images: null };
    }
    
    // Always point to https://shutdown.[env].showtime.com/[file name]
    const statusFile = (__APP_TYPE__ === APP_TYPES.ott) ? 'images.json' : 'stat-images.json';
    const host = window.location.host;
    let env = '';
    if (host.includes('maint')) {
      env = '.qa-maint';
    } else if (host.includes('qa1')) {
      env = '.qa1';
    } else if (host.includes('qa2')) {
      env = '.qa2';
    } else if (host.includes('stage')) {
      env = '.stage';
    }

    const appStatusUrl = `https://shutdown${env}.showtime.com/${statusFile}`;
    const statusResponse = { active: true, images: null };

    // Image size keys
    const desktopSize = '1920x1080';
    const mobileSize = '768x1152';

    try {
      const response = await fetch(appStatusUrl, {
        method: 'GET',
        headers: new Headers({ 
          'Content-Type': 'application/json',
          'Access-Control-Request-Method': 'GET',
        }),
        cache: 'default',
      });
      if (response.ok) {
        const json = await response.json();
        statusResponse.active = false;
        // Assume images exist
        statusResponse.images = {
          desktop: json[desktopSize],
          mobile: json[mobileSize],
        };
        this.logger.log('Response valid, app inactive', statusResponse);
        return statusResponse;
      }
      
      this.logger.log('Response error, app is still active.');
      return statusResponse;
    } catch (error) {
      this.logger.log('Response error, app is still active.');
      return statusResponse;
    }
  },

  /**
   * Display app shutdown image
   */
  appLockdown (images) {
    const container = document.getElementById('website-2000');
    const tpl = `
      <picture>
        <source srcset="${images.desktop}" media="(min-width: 768px)">
        <img src="${images.mobile}" style="position: fixed; top: 0; left: 0; width: 100%; height: 100%; object-fit: contain; object-position: center center;" />
      </picture>
    `;

    if (container) {
      container.innerHTML = tpl;
    } else {
      this.logger.error('Container div not found.');
    }
  },

  /**
   * Redirects to paramount based on requested path.
   */
  appRedirect (requestedPath) {
    const defaultRedirect = 'https://www.paramountplus.com/browse/showtime/';
    const moviesRedirect = 'https://www.paramountplus.com/movies/showtime/';
    const shoRedirect = 'https://www.paramountplus.com/brands/showtime/';

    const redirectRoutes = [
      { path: '/series/1034344', redirect: 'https://www.paramountplus.com/shows/yellowjackets/' },
      { path: '/series/1042640', redirect: 'https://www.paramountplus.com/shows/waco-the-aftermath/' },
      { path: '/series/1036123', redirect: 'https://www.paramountplus.com/shows/the-12th-victim/' },
      { path: '/series/1035652', redirect: 'https://www.paramountplus.com/shows/spector/' },
      { path: '/series/1032991', redirect: 'https://www.paramountplus.com/shows/the-circus/' },
      { path: '/series/1036164', redirect: 'https://www.paramountplus.com/shows/murder-in-big-horn/' },
      { path: '/episode/3507222', redirect: shoRedirect },
      { path: '/episode/3518245', redirect: shoRedirect },
      { path: '/movie', redirect: moviesRedirect },
      { path: '/play', redirect: moviesRedirect },
      { path: '/schedule', redirect: 'https://www.paramountplus.com/live-tv/stream/y0Rot3bPoyjWCUqwE1vxXHOYHuV32Hwg/' },
      { path: '/category/407/sports/boxing', redirect: 'https://www.paramountplus.com/collections/sports-hub/' },
    ];

    // Check for a match, or just do the default redirect
    const match = redirectRoutes.find((route) => requestedPath.indexOf(route.path) > -1);
    if (match) {
      window.location.replace(match.redirect);
    } else {
      window.location.replace(defaultRedirect);
    }
  },

  /**
   * App data pre-fetch. Always load now, user, and dictionary before App start.
   * 'now' must be called first to establish a proper server session.
   * @returns {Promise} Promise object represents successful pre-fetch data responses.
   */
  async getPrefetchData () {
    return new Promise((resolve, reject) => {
      api.get('now')
        .then((nowData) => {
          const userFetch = api.get('user');
          const dictionaryFetch = api.get('dictionary');

          Promise.allSettled([userFetch, dictionaryFetch])
            .then((responses) => {
              const errorResponse = responses.find((response) => response.status === 'rejected' && response.reason.code !== 'error.session.loggedOut');
              if (errorResponse) {
                reject(errorResponse.reason);
                return;
              }

              const [userResponse, dictionaryResponse] = responses;

              resolve({
                nowData,
                dictionaryData: dictionaryResponse.value,
                userData: userResponse.status === 'fulfilled' ? userResponse.value : null,
              });
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * Sets vuex state and instantiates Vue instance.
   * @param {Object} preFetchData
   */
  instantiateVue ({ nowData, userData, dictionaryData }) {
    store.commit('now/setNow', nowData);
    store.commit('dictionary/setDictionary', dictionaryData);
    if (userData) {
      store.commit('user/setUser', userData);
    }

    const app = createApp(AppMain);

    // Set static App config
    app.config.globalProperties.$config = config;

    // Optimizely set up
    const optimizelyClient = Optimizely.createInstance({ sdkKey: __OPTIMIZELY_SDK_KEY__ });
    optimizelyClient.onReady().then(() => {
      app.config.globalProperties.$optimizelyClient = optimizelyClient;
      app.config.globalProperties.$optimizelyUser = {
        device: optimizelyClient.createUserContext(nowData.tveDeviceId), // user context bucketed via tveDeviceId
        authz: null, // Authorized user context bucketed via tveUserId
      };
    }).catch((err) => {
      this.logger.error('Error creating Optimizely user context:', err);
    }).finally(() => {
      app.use(router);
      app.use(store);
      app.mount('#website-2000');
    });
  },

  /**
   * Performs data pre-fetch, commits responses to vuex, and instantiates Vue instance with static configs.
   * Any startup error displays generic error messaging to the user.
   */
  async start () {
    this.logger = new Logger('AppLauncher');

    try {
      const appStatus = await this.getAppActiveStatus();
      if (appStatus.active) {
        const preFetchData = await this.getPrefetchData();
        this.instantiateVue(preFetchData);
      } else {
        /**
         * If app is inactive, check if the root path (/) was being accessed -
         * if so, display the lockdown screen, otherwise redirect according to config.
         */
        const pathname = window.location.pathname;
        const search = window.location.search;
        // 'search' check to accomodate '/?activate' from redirect list
        if (pathname === '/' && search.indexOf('?activate') < 0) {
          this.appLockdown(appStatus.images);
        } else {
          this.appRedirect(pathname === '/' ? '/activate' : pathname);
        }
      }
    } catch (error) {
      this.logger.error('App start error', error);
      this.showAppError();
    }
  },
};
