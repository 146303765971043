const NotFoundPage = () => import('@pages/NotFoundPage.vue');
const PlayerPage = () => import('@pages/PlayerPage.vue');
const FormControlsPage = () => import('@pages/FormControlsPage.vue');

import { getDefaultAccess } from '@utils/route-default-access.js';

/**
 * Route access configs.
 * @typedef {Object} ShoRouteAccessConfig
 * @property {boolean} authorized - Indicates if a route is accessible to authorized users.
 * @property {boolean} authenticated - Indicates if a route is accessible to authenticated users.
 * @property {boolean} unauthenticated - Indicates if a route is accessible to unauthenticated users.
 * @property {boolean} mobile - Indicates if a route is accessible on mobile devices.
 */

/**
 * Route access object - access configs for OTT and STAT.
 * @typedef {Object} ShoRouteAccess
 * @property {ShoRouteAccessConfig} ott - Default access object for OTT
 * @property {ShoRouteAccessConfig} stat - Default access object for STAT
 */

/**
 * Route Meta object.
 * @typedef {Object} ShoRouteMeta
 * @property {ShoRouteAccess} access - Access configs for OTT & STAT
 * @property {Object} [authnFailureRoute] - Router object for authentication-required failure redirect
 */

export default [
  // NOTE: Uncomment to demo form controls
  {
    path: '/forms',
    name: 'formControls',
    component: FormControlsPage,
  },

  {
    path: '/play/:titleId',
    name: 'play',
    component: PlayerPage,
    props: (route) => ({ titleId: Number(route.params.titleId) }),
    meta: {
      access: {
        ott: getDefaultAccess('ott'),
        stat: getDefaultAccess('stat'),
      },
    },
  },

  {
    path: '/play/:channel',
    name: 'playLive',
    component: PlayerPage,
    props: (route) => ({ titleId: route.params.channel }),
    meta: {
      access: {
        ott: getDefaultAccess('ott'),
        stat: getDefaultAccess('stat'),
      },
    },
  },

  /**
   * Oculus and Portal help center redirects.
   */
  {
    path: '/portal',
    beforeEnter: () => {
      const portalUrl = 'https://help.showtime.com/hc/en-us/articles/13557854901019-What-is-happening-to-SHOWTIME-on-Facebook-Portal-';
      window.location.replace(portalUrl);
    },
  },
  
  {
    path: '/oculus',
    beforeEnter: () => {
      const oculusUrl = 'https://help.showtime.com/hc/en-us/articles/13557747084059-What-is-happening-to-SHOWTIME-on-Oculus-';
      window.location.replace(oculusUrl);
    },
  },

  {
    path: '/404',
    name: 'notFound',
    component: NotFoundPage,
  },

  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'notFound' },
  },
];
