import api from '@api/api.js';
import Logger from '@utils/logger.js';

const logger = new Logger('mso');

const url = 'mso';

export const defaults = {
  accountType: '',
  authType: '',
  authURL: '',
  externalLogout: false,
  fetching: false,
  logInText: '',
  logOutLink: '',
  loggedInImage: null,
  mso: '',
  msoId: '',
  parentalControlMessage: '',
  parentalControls: '',
  partitionedForms: false,
  pickerImage: null,
  platforms: null,
  playerId: '',
  registration: null,
  settings: null,
};

export const state = { ...defaults };

export const actions = {
  /**
   * @param {Object} context - Vuex context.
   * @param {Number} mso - MSO Id
   */
  async getMSOData ({ commit }, mso) {
    commit('setFetching');
    try {
      const msoData = await api.get(`${url}/${mso}`);
      logger.log('our msoData:', msoData);
      commit('setMSOData', msoData);
    } catch (error) {
      logger.error(error);
    }
    commit('setFetching', false);
  },
  
  /**
   * @param {Object} context - Vuex context.
   */
  async logout ({ commit }) {
    commit('setFetching');
    try {
      await fetch(state.logOutLink, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-STAT-contentVersion': 'STAT',
          'X-STAT-displayType': 'WEB',
        },
      });
    } catch (error) {
      logger.error(error);
    }
    commit('setMSOData', { ...defaults });
    commit('setFetching', false);
  },
};

export const mutations = {
  setMSOData (state, response) {
    logger.log('setting MSO data');
    Object.assign(state, response);
    state.fetching = false;
  },
  setFetching (state, fetching = true) {
    state.fetching = fetching;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
