const StartFlowPage = () => import('@pages/paywall/StartFlowPage.vue');
import { REGISTRATION_FLOWS } from '@utils/constants.js';

const meta = {
  section: 'paywall',
  /* @type {ShoRouteAccessConfig} */
  access: {
    ott: {
      authorized: false,
      authenticated: true,
      unauthenticated: true,
      mobile: true,
    },
  },
};

/**
 * Routes that trigger the 'attribution' purchase flow. (Default flow, add attribution id at purchase)
 */
export default [
  {
    path: '/hilton',
    name: 'attributionHilton',
    component: StartFlowPage,
    props: { flowId: REGISTRATION_FLOWS.attribution, partnerId: 'hotel-hilton' },
    meta,
  },
  {
    path: '/marriott',
    name: 'attributionMarriot',
    component: StartFlowPage,
    props: { flowId: REGISTRATION_FLOWS.attribution, partnerId: 'hotel-marriot' },
    meta,
  },
  {
    path: '/sheraton',
    name: 'attributionSheraton',
    component: StartFlowPage,
    props: { flowId: REGISTRATION_FLOWS.attribution, partnerId: 'hotel-sheraton' },
    meta,
  },
  {
    path: '/hotel',
    name: 'attributionHotel',
    component: StartFlowPage,
    props: { flowId: REGISTRATION_FLOWS.attribution, partnerId: 'hotel-generic' },
    meta,
  },
  {
    path: '/hotels',
    name: 'attributionHotels',
    component: StartFlowPage,
    props: { flowId: REGISTRATION_FLOWS.attribution, partnerId: 'hotel-generic' },
    meta,
  },
  {
    path: '/tivo',
    name: 'attributionTivo',
    component: StartFlowPage,
    props: { flowId: REGISTRATION_FLOWS.attribution, partnerId: 'tivo' },
    meta,
  },
];
