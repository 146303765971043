<template>
  <span class="sho-icon" :class="{ offset: isOffset }">
    <component :is="icon" :fill="fill" :aria-label="label" />
  </span>
</template>
<script>
import { defineAsyncComponent, markRaw } from 'vue';

export default {
  name: 'ShoIcon',
  props: {
    /**
     * Specifies icon file to load.
     */
    type: {
      type: String,
      required: true,
    },
    /**
     * SVG fill color. Defaults to match font color.
     */
    fill: {
      type: String,
      default: 'currentColor',
    },
    /**
     * Optional icon label for usability.
     */
    label: {
      type: String,
      default: null,
    },
    height: {
      type: String,
      default: null,
    },
    width: {
      type: String,
      default: null,
    },
    top: {
      type: String,
      default: null,
    },
    isOffset: {
      type: Boolean,
      default: null,
    },
  },
  data () {
    return {
      icon: null,
      svgheight: this.height || '1em',
      svgwidth: this.width || 'auto',
      svgtop: this.isOffset ? '0.125em' : this.top,
    };
  },
  watch: {
    type: {
      immediate: true,
      handler () {
        if (this.type) {
          this.icon = markRaw(defineAsyncComponent(() =>
            import(`@assets/img/icons/svg/${this.type}.svg`)
          ));
        }
      },
    },
  },
};
</script>
<style lang="scss" scoped>
  .sho-icon {
    align-self: center;
    display: inline-flex;

    svg {
      height: v-bind(svgheight);
      position: relative;
      top: v-bind(svgtop);
      width: v-bind(svgwidth);
    }

    &.offset svg {
      display: inline-block;
    }
  }
</style>
