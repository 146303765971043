<template>
  <div class="my-list-items">
    <h1>My List</h1>
    <MyListSkeleton v-if="!fetched" />
    <p v-if="isEmpty" class="empty">Save your favorite programs to My List so you can quickly find them later.</p>
    <ul v-else class="item-list">
      <li v-for="item in items" :key="`my-list-${item.id}`" class="list-item">
        <MyListItem 
          :id="item.id"
          :name="item.name"
          :type="item.type"
          :release-year="item.releaseYear"
          :images="item.images"
          :processing="item.processing"
          @delete-click="onDeleteClick" 
        />
      </li>
    </ul>
    <div v-if="fetching" class="lds-facebook"><div /><div /><div /></div>
  </div>
</template>

<script>
import MyListItem from '@components/my-list/MyListItem.vue';
import MyListSkeleton from '@components/my-list/MyListSkeleton.vue';

/**
 * My List UI component
 */
export default {
  name: 'MyListItems',
  components: {
    MyListItem,
    MyListSkeleton,
  },
  emits: ['delete-click'],
  props: {
    fetching: {
      type: Boolean,
      default: false,
    },
    fetched: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    /**
     * Indicates if the My List item array is empty.
     * @returns {boolean}
     */
    isEmpty () {
      return this.fetched && (!this.items || !this.items.length);
    },
  },
  methods: {
    onDeleteClick (item) {
      this.$emit('delete-click', item);
    },
  },
};
</script>

<style lang="scss" scoped>
  h1 {
    @extend %ds-headline;
    margin: 0 0 12px;
  }

  .my-list-items {
    position: relative;
  }

  .item-list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .list-item {
    border-top: 1px solid $ds-medium_grey;
    padding: 16px 0;
  }

  .empty {
    @extend %ds-body;
    border-top: 1px solid $ds-medium_grey;
    padding: 16px 0 0;
  }

  /* Loading Indicator */

  .lds-facebook {
    display: inline-block;
    height: 80px;
    left: 50%;
    margin-left: -40px;
    opacity: 0.45;
    position: absolute;
    top: 160px;
    width: 80px;
  }

  .lds-facebook div {
    animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    background: #fff;
    display: inline-block;
    left: 8px;
    position: absolute;
    width: 16px;
  }

  .lds-facebook div:nth-child(1) {
    animation-delay: -0.24s;
    left: 8px;
  }

  .lds-facebook div:nth-child(2) {
    animation-delay: -0.12s;
    left: 32px;
  }

  .lds-facebook div:nth-child(3) {
    animation-delay: 0;
    left: 56px;
  }

  @keyframes lds-facebook {
    0% {
      height: 64px;
      top: 8px;
    }

    50%,
    100% {
      height: 32px;
      top: 24px;
    }
  }
</style>
