<template>
  <button :class="{ 'cursor-pointer': cursorPointer }" :type="buttonType">
    <ShoIcon :type="icon" :height="height" :width="width" :fill="fill" />
  </button>
</template>

<script>
import ShoIcon from '@components/ui/ShoIcon.vue';

export default {
  components: { ShoIcon },
  props: {
    cursorPointer: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      required: true,
    },
    buttonType: {
      type: String,
      default: 'button',
    },
    height: {
      type: String,
      default: '16px',
    },
    width: {
      type: String,
      default: 'auto',
    },
    fill: {
      type: String,
      default: 'white',
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  background: none;
  border: none;
  color: inherit;
  line-height: 0;
  opacity: 0.7;
  padding: 4px;
  transition: opacity 300ms;

  &:hover,
  &:focus-visible {
    opacity: 1;
  }

  &:focus-visible {
    background: rgba($ds-white, 40%);
    border-radius: 3px;
    outline: none;
  }
}

.cursor-pointer {
  cursor: pointer;
}
</style>
