import api from '@api/api.js';

export const URL = 'mixed/collections';

const SPORTS_CATEGORY_ID = 407;
const BELLATOR_COLLECTION_ID = 12936;

export const getDefaults = () => ({
  categoryMetadata: null,
  items: [],
  promotions: [],
  fetching: false,
  fetched: false,
});

export const state = getDefaults();

export const mutations = {
  setCategory (state, response) {
    state.promotions = response.promotions;
    state.categoryMetadata = response.categoryMetadata;
    state.items = response.items;
    state.fetching = false;
    state.fetched = true;
  },
  setFetching (state, fetching = true) {
    state.error = null;
    state.fetching = fetching;
  },
  setError (state, error) {
    state.error = error;
    state.fetching = false;
  },
  reset (state) {
    Object.assign(state, getDefaults());
  },
};

export const actions = {
  async getCategory ({ commit }, collectionId) {
    commit('setFetching');
    try {
      const response = await api.get(`${URL}/${collectionId}`);

      // Hack to fetch/filter Bellator MMA titles
      if (collectionId === SPORTS_CATEGORY_ID) {
        const bellatorCategory = await api.get(`${URL}/${BELLATOR_COLLECTION_ID}`);
        const bellatorTitleIDList = bellatorCategory?.items.map((item) => item.id);

        // Splice in Bellator MMA shelf and items if appropriate
        if (bellatorCategory?.categoryMetadata) {
          response?.categoryMetadata?.shelves.splice(
            response?.categoryMetadata?.shelves.length - 1,
            0,
            {
              collectionType: 'titles',
              label: bellatorCategory.categoryMetadata.name,
              titleType: 'mma',
            }
          );

          // Assign items an MMA flag until the API is updated to do this
          response?.items.map((item) => {
            if (bellatorTitleIDList.includes(item.id)) {
              if (item.flags) {
                item.flags.push('MMA');
              } else {
                item.flags = ['MMA'];
              }
            }
            return item;
          });
        }
      }
      
      commit('setCategory', response);
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   * Resets vuex state.
   * @param {Object} context 
   */
  reset ({ commit }) {
    commit('reset');
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};

