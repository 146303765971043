<template>
  <div class="sho-drawer" @keyup.esc="$emit('close-click')">
    <Transition name="protection-fade">
      <div v-if="open" class="protection" @click="onCloseClick" />
    </Transition>
    
    <Transition name="drawer-slide">
      <div v-if="open" class="drawer" data-name="drawer">
        <button type="button" class="close-btn" data-name="close-drawer-button" @click="onCloseClick">
          <CloseButton class="close-ico" alt="Close My List" />
        </button>
        <div class="content">
          <slot />
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
import CloseButton from '@assets/img/icons/svg/close-button.svg';

/**
 * Drawer UI component.
 */
export default {
  name: 'ShoDrawer',
  components: {
    CloseButton,
  },
  emits: ['close-click'],
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onCloseClick () {
      this.$emit('close-click');
    },
  },
};
</script>

<style lang="scss" scoped>
  .sho-drawer {
    height: 0;
    width: 0;
  }

  .protection {
    display: none;
  }

  .drawer {
    background: $ds-charcoal_grey;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateZ(0); /* Enable Chrome hardware acceleration */
    width: 100%;
  }

  .close-btn {
    background: none;
    border: none;
    border-radius: 2px;
    line-height: 1;
    opacity: 0.75;
    position: absolute;
    right: 24px;
    top: 16px;
  }

  .close-ico {
    fill: white;
    width: 18px;
  }

  .close-btn:hover {
    opacity: 1;
  }

  .close-btn:focus-visible {
    background: rgba(255, 255, 255, 25%);
    opacity: 1;
    outline: none;
  }

  .content {
    flex-grow: 3;
    overflow-x: hidden;
    overflow-y: auto;
  }

  @media (min-width: 768px) {
    .protection {
      backdrop-filter: blur(1px);
      background: rgba(0, 0, 0, 55%);
      bottom: 0;
      display: block;
      left: 0;
      opacity: 1;
      position: absolute;
      right: 0;
      top: 0;
    }

    .close-btn {
      padding: 4px 5px 3px;
    }

    .drawer {
      box-shadow: -5px 0 15px rgba(0, 0, 0, 50%);
      width: var(--app-drawer-width);
    }

    /* Drawer Transition Animations */
    .drawer-slide-enter-active {
      transition: right 300ms linear;
    }

    .drawer-slide-leave-active {
      /* Slows down closing animation so there's no visual offset */
      transition: right 650ms linear;
    }

    .drawer-slide-enter-from,
    .drawer-slide-leave-to {
      right: calc(-1 * var(--app-drawer-width));
    }

    /* Protection Transition Animations */
    .protection-fade-enter-active,
    .protection-fade-leave-active {
      transition: opacity 600ms;
    }

    .protection-fade-enter-from,
    .protection-fade-leave-to {
      opacity: 0;
    }
  }
</style>
