<template>
  <div class="my-list-item" :class="itemClass">
    <div class="meta-wrapper">
      <div class="poster-wrapper">
        <router-link v-if="isSeries" :to="infoRoute" class="poster-action">
          <img v-if="formattedItemImage" :src="formattedItemImage">
        </router-link>
        <router-link v-else :to="playRoute" class="poster-action">
          <div class="play-cta">
            <PlayIcon class="play-icon" />
            <span class="play-label">Play</span>
          </div>
          <img v-if="formattedItemImage" :src="formattedItemImage">
        </router-link>
      </div>
      <router-link :to="infoRoute" class="item-meta">
        <h2 class="item-name">{{ name }}</h2>
        <p v-if="releaseYear">{{ releaseYear }}</p>
      </router-link>
    </div>
    <IconButton 
      class="delete-btn" 
      :aria-label="`delete ${name} from list`" 
      icon="trash-can"
      @click="onDeleteClick" 
      height="14px"
      cursor-pointer
    />
  </div>
</template>

<script>
import { ASSET_TYPES, MY_LIST_IMAGE_TYPES } from '@utils/constants.js';
import { imageBuilder } from '@utils/image-builder.js';
import IconButton from '@components/ui/IconButton.vue';
import PlayIcon from '@assets/img/icons/svg/play.svg';

export default {
  name: 'MyListItem',
  components: {
    PlayIcon,
    IconButton,
  },
  emits: ['delete-click'],
  props: {
    id: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    releaseYear: {
      type: Number,
      default: null,
    },
    type: {
      type: String,
      required: true,
    },
    images: {
      type: Array,
      required: true,
    },
    processing: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    /**
     * Dynamic css classes based on item type and delete processing.
     * @returns {Object}
     */
    itemClass () {
      return {
        'item-series': this.type === ASSET_TYPES.series,
        'item-movie': this.type === ASSET_TYPES.movie,
        'processing': this.processing,
      };
    },
    /**
     * Route to series or title detail page, based on item type.
     * @returns {Object}
     */
    infoRoute () {
      if (this.type === ASSET_TYPES.series) {
        return { name: 'seriesDetail', params: { id: this.id } };
      }

      return { name: 'movieDetail', params: { id: this.id } };
    },
    /**
     * Route to title playback.
     * @returns {Object}
     */
    playRoute () {
      return { name: 'play', params: { titleId: this.id } };
    },
    /**
     * Indicates if an item type is Series.
     * @returns {boolean}
     */
    isSeries () {
      return this.type === ASSET_TYPES.series;
    },
    /**
     * Returns correct image url for item based on type. If none
     * is found, returns null.
     * @returns {string}
     */
    itemImageUrl () {
      let imageUrl = '';
      if (this.type ===  ASSET_TYPES.series) {
        imageUrl = this.images.find((image) => image.type === MY_LIST_IMAGE_TYPES.playlistSeriesListDisplay);
      } else {
        imageUrl = this.images.find((image) => image.type === MY_LIST_IMAGE_TYPES.playlistListDisplay);
      }
      return imageUrl && imageUrl.url ? imageUrl.url : null;
    },
    /**
     * Uses imageBuilder util to return formatted image url, given the image url
     * found by {@link itemImageUrl}. If no image url exists, returns null, and MyListItem
     * will display a placeholder instead.
     * @returns {string}
     */
    formattedItemImage () {
      if (this.itemImageUrl) {
        return imageBuilder(this.itemImageUrl, 'MIXED_SERIES_MOVIES_DRAWER', this.type);
      }
      return null;
    },
  },
  methods: {
    /**
     * Handles delete button click event. Dispatches remove action.
     */
    async onDeleteClick () {
      if (this.processing) {
        return;
      }

      this.$emit('delete-click', { id: this.id, type: this.type });
    },
  },
};
</script>

<style lang="scss" scoped>
  .my-list-item {
    position: relative;
    transition: opacity 200ms;
  }

  .my-list-item.processing {
    opacity: 0.5;
    pointer-events: none;
  }

  .my-list-item.processing .close {
    pointer-events: none;
  }

  .meta-wrapper {
    align-content: stretch;
    align-items: stretch;
    display: flex;
  }

  .poster-wrapper {
    background: $ds-charcoal_grey url("~@assets/img/placeholders/default_title_img_839x839.jpg") no-repeat center;
    background-size: 250px;
    flex: 0 0 100px;
    position: relative;
  }

  .item-series .poster-wrapper {
    min-height: 100px;
  }

  .item-movie .poster-wrapper {
    min-height: 153px;
  }

  img {
    display: block;
    width: 100px;
  }

  .play-cta {
    align-items: center;
    background-color: rgba(0, 0, 0, 0%);
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: background-color 300ms;
  }

  .play-icon {
    margin-bottom: 6px;
    opacity: 0;
    transition: opacity 300ms;
    width: 18px;
  }

  .play-label {
    @extend %ds-input-placeholder-sm;
    opacity: 0;
    transition: opacity 300ms;
  }

  .my-list-item:hover .play-icon {
    opacity: 0.45;
  }

  .my-list-item:hover .play-cta:hover {
    background: rgba(0, 0, 0, 50%);
  }

  .my-list-item:hover .play-cta:hover .play-icon,
  .my-list-item:hover .play-cta:hover .play-label {
    opacity: 0.75;
  }

  .item-meta {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    padding: 0 16px;
    text-decoration: none;
  }

  .item-meta p {
    @extend %ds-body;
    color: $ds-sho_neutral_light;
    margin: 0 0 4px;
  }

  .item-name {
    @extend %ds-body-bold;
    margin: 0 0 4px;
  }

  .item-name:hover {
    color: $ds-sho_primary;
  }

  .delete-btn {
    bottom: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    transition: opacity 300ms;
  }

  .my-list-item:hover .delete-btn,
  .my-list-item:focus-within .delete-btn {
    opacity: 0.7;
  }

  .my-list-item:hover .delete-btn:hover,
  .my-list-item:focus-within .delete-btn:focus {
    opacity: 1;
  }
</style>
