import api from '@api/api.js';

import Logger from '@utils/logger.js';
const logger = new Logger('paywall-authz');

const BASE_URL = 'paywall';
const REFRESH_MS = 1000 * 60 * 60; // Time to wait between re-fetches

// Holds on to fetch promise to prevent multiple simultaneous fetches.
let fetchPromise;

const getDefaults = () => ({
  pages: {},
  fetched: false,
});

export const state = getDefaults();

export const mutations = {
  setResponse (state, response) {
    const parsedPages = {};
    // Flatten pages[pageName].content for easier UI use...
    for (const [pageName, pageContent] of Object.entries(response.pages)) {
      const parsedPage = { ...pageContent, ...pageContent.content };
      delete parsedPage.content;
      parsedPages[pageName] = parsedPage;
    }
    state.pages = parsedPages;
    state.fetched = new Date().getTime();
  },
  resetState (state) {
    Object.assign(state, getDefaults());
  },
};

export const actions = {
  async fetch ({ commit, state }, options = {}) {
    if (fetchPromise) {
      logger.log('Awaiting existing fetch');
      // If there is an existing promise fetching, skip new fetch
      await fetchPromise;
      return;
    }

    // If paywall data already fetched, skip re-fetch
    if (!options.cacheBust && state.fetched && new Date().getTime() - state.fetched < REFRESH_MS) {
      logger.log('Authz Paywall already fetched');
      return;
    }

    logger.log('Fetching paywall');
    let url = BASE_URL;
    const params = URLSearchParams && new URLSearchParams(location.search);
    if (params && params.get('b') || params.get('offer')) {
      url = `${url}?cid=${params.get('b') || params.get('offer')}`;
    }

    fetchPromise = api.get(url);
    const response = await fetchPromise;
    
    fetchPromise = null;

    if (!response.pages) {
      logger.error('No pages in paywall response');
      return;
    }

    commit('setResponse', response);
  },
  async reset ({ commit }) {
    commit('resetState');
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
