import api from '@api/api.js';
import { ASSET_TYPES } from '@utils/constants.js';

export const URLS = {
  get: 'v3/user/my-list',
  title: 'v3/user/my-list/title',
  series: 'v3/user/my-list/series',
};

// Throttle how often My List is refreshed on requests
const EXPIRES_IN_MS = 30 * 1000;

// How long a notification should stay visible
const NOTIFICATION_TIME_MS = 5 * 1000;

/**
 * Checks if state data is safe to be re-fetched to avoid over-calling the API.
 * @param {number} fetchedAt - Last fetch time.
 * @param {number} expiresInMs - Length of time in ms to wait between new fetches.
 * @returns {boolean}
 */
export const dataIsExpired = (fetchedAt, expiresInMs) => Date.now() >= fetchedAt + expiresInMs;

/**
 * Defaults for the My List store.
 * @typedef {Object} MyListDefaults
 * @property {boolean} fetching - Indicates if My List is being fetched.
 * @property {boolean} fetched - Indicates if My List has been fetched.
 * @property {number} fetchedAt - Time of last fetch
 * @property {Object[]} items - Items in My List.
 * @property {Object} notification - Item metadata for notification display.
 * @property {Object} error - API error responses.
 */

/**
 * Returns immutable My List state defaults.
 * @returns {MyListDefaults} - Default state data.
 */
export const getDefaults = () => ({
  fetching: false,
  fetched: false,
  fetchedAt: null,
  items: [],
  notification: null,
  error: null,
});

export const state = getDefaults();

export const getters = {
  isInMyList: (state) => (type, id) => state.items.some((item) => {
    // Handle generic 'title' type that comes from promo modules API
    if (type === 'title') {
      return item.id === id && item.type !== ASSET_TYPES.series;
    } else {
      return item.id === id && item.type === type;
    }
  }),
};

export const mutations = {
  setFetching (state, fetching) {
    state.fetching = fetching;
  },
  setItems (state, items) {
    state.items = items;
    state.fetching = false;
    state.fetched = true;
    state.fetchedAt = Date.now();
  },
  addItem (state, item) {
    state.items.unshift(item);
  },
  removeItem (state, { id, type }) {
    state.items = state.items.filter((item) => !(item.id === id && item.type === type));
  },
  /**
   * Updates a single item's 'processing' value while preserving list array order.
   * @param {Object} state 
   * @param {Object} payload
   * @param {Object} payload.item - Item to update
   * @param {number} payload.item.id - Item id
   * @param {string} payload.item.type - Item type
   * @param {boolean} payload.processing - Item processing setting 
   */
  setProcessing (state, { item, processing = true }) {
    if (state.items && state.items.length) {
      state.items = [
        ...state.items.map((mapItem) => {
          if (mapItem.id === item.id && mapItem.type === item.type) {
            mapItem.processing = processing;
          }

          return mapItem;
        }),
      ];
    }
  },
  showNotification (state, notification) {
    state.notification = notification;
  },
  hideNotification (state) {
    state.notification = null;
  },
  setError (state, error) {
    state.fetching = false;
    state.error = error;
  },
  resetError (state) {
    state.error = null;
  },
  reset (state) {
    Object.assign(state, getDefaults());
  },
};

export const actions = {
  /**
   * Handles requests for My List data. Throttles requests to once every 'expiresIn' milliseconds.
   * @param {Object} context - Vuex store context.
   */
  async getMyList ({ state, commit }) {
    if (!state.fetching && (!state.fetched || dataIsExpired(state.fetchedAt, EXPIRES_IN_MS))) {
      try {
        commit('resetError');
        commit('setFetching', true);
        const response = await api.get(URLS.get);
        commit('setItems', response.items || []);
      } catch (error) {
        commit('setError', error);
      }
    }
  },
  /**
   * Adds a title to My List. On success, adds response to items array.
   * @param {Object} context - Vuex store context.
   * @param {Object} payload
   * @param {number} payload.id - Title ID to add.
   * @param {Boolean} payload.isPrePremiere - Is series in pre-premiere state
   */
  async addTitle ({ commit, dispatch }, { id, isPrePremiere = false }) {
    try {
      commit('resetError');
      const title = await api.post(`${URLS.title}/${id}`);
      if (!isPrePremiere) {
        commit('addItem', title);
        dispatch('showNotification', title);
      }
    } catch (error) {
      commit('setError', error);
      throw new Error('Something went wrong. Please try again.');
    }
  },
  /**
   * Adds a series to My List. On success, adds response to items array.
   * @param {Object} context - Vuex store context.
   * @param {Object} payload
   * @param {number} payload.id - Series ID to add.
   * @param {Boolean} payload.isPrePremiere - Is series in pre-premiere state
   */
  async addSeries ({ commit, dispatch }, { id, isPrePremiere = false }) {
    try {
      commit('resetError');
      const series = await api.post(`${URLS.series}/${id}`);
      if (!isPrePremiere) {
        commit('addItem', series);
        dispatch('showNotification', series);
      }
    } catch (error) {
      commit('setError', error);
      throw new Error('Something went wrong. Please try again.');
    }
  },
  /**
   * Series shortcut for removeItem
   * @param {Object} context - Vuex module context 
   * @param {number} id - Series id
   */
  async removeSeries ({ dispatch }, id) {
    try {
      await dispatch('removeItem', { type: ASSET_TYPES.series, id });
    } catch (error) {
      throw new Error(error);
    }
  },
  /**
   * Removes an item from My List based on type.
   * @param {Object} context - Vuex module context 
   * @param {Object} item - Item to remove
   * @param {number} item.id - Title id
   * @param {string} item.type - Asset type
   * @param {Boolean} item.isPrePremiere - Is item in pre-premiere state
   */
  async removeItem ({ commit }, item) {
    const BASE_URL = item.type === ASSET_TYPES.series ? URLS.series : URLS.title;
    try {
      commit('resetError');
      commit('setProcessing', { item, processing: true });
      await api.del(`${BASE_URL}/${item.id}`);
      if (!item.isPrePremiere) {
        commit('removeItem', item);
      }
    } catch (error) {
      commit('setError', error);
      commit('setProcessing', { item, processing: false });
      throw new Error('Something went wrong. Please try again.');
    }
  },
  /**
   * Populates notification data in state and sets a timer to automatically close it.
   * @param {Object} context - Vuex store context.
   * @param {Object} notification - Notification payload.
   */
  showNotification ({ commit }, notification) {
    commit('showNotification', notification);
    setTimeout(() => { 
      commit('hideNotification'); 
    }, NOTIFICATION_TIME_MS);
  },
  /**
   * Responds to a hide notification action.
   * @param {Object} context - Vuex store context.
   */
  hideNotification ({ commit }) {
    commit('hideNotification');
  },
  /**
   * Reset state to defaults
   * @param {Object} context - Store context
   */
  reset ({ commit }) {
    commit('reset');
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
