import api from '@api/api.js';
import Logger from '@utils/logger.js';

const logger = new Logger('user-series');

export const url = 'user/series';

export const defaults = {
  seriesId: null,
  fetching: false,
  fetched: false,
  resumeWatching: null,
  episodes: [],
  isInMyList: false,
  error: null,
};

const state = { ...defaults };

let currentFetch = null;

const actions = {
  /**
   * Fetches user series information
   * @param {Object} context
   * @param {number} seriesId
   */
  async getUserSeries ({ commit }, seriesId) {
    commit('setFetching', true);
    try {
      if (currentFetch) {
        await currentFetch;
      }
      currentFetch = api.get(`${url}/${seriesId}`);
      const response = await currentFetch;
      currentFetch = null;
      commit('setUserSeries', { ...response, seriesId });
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   * Resets module state
   * @param {Object} context
   */
  resetUserSeries ({ commit }) {
    commit('resetState');
  },
  /**
   * Return user series request without manipulating state
   * @param {Object} context 
   * @param {number} seriesId 
   * @returns {Promise}
   */
  getUserSeriesRequest (contex, seriesId) {
    return api.get(`${url}/${seriesId}`);
  },
};

export const mutations = {
  setUserSeries (state, response) {
    // It's possible that the API does not return keys for 'episodes' or 'resumeWatching',
    // so we need to fill those with the defaults
    Object.assign(state, { ...defaults }, response);
    state.error = null;
    state.fetched = true;
    state.fetching = false;
  },
  resetState () {
    Object.assign(state, { ...defaults });
  },
  setFetching (state, fetching) {
    state.fetching = fetching;
    if (fetching) {
      state.error = null;
    }
  },
  setError (state, error) {
    state.error = error;
    state.fetched = true;
    state.fetching = false;
    if (error.code !== 'error.session.loggedOut') {
      logger.error(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
