<template>
  <div class="mini-sender-container" @click="onMiniSenderClick">
    <div class="cast-thumbnail-container">
      <div
        :style="{'background-image': `url(${backgroundImage})`}"
        class="cast-thumbnail"
      />
    </div>
    <div class="mini-sender-text-content-container">
      <p class="casting-to mini-sender-text-content">CASTING TO {{ deviceName }}</p>
      <p class="mini-sender-text-content">{{ vodDisplayName }}</p>
    </div>
    <MinimizeSenderButton />
  </div>
</template>
<script>
import MinimizeSenderButton from '@components/mini-sender/MinimizeSenderButton.vue';

import { TITLE_TYPE } from '@utils/player-constants';
import { findWhere } from '@player/utils/methods';
import { mapState } from 'vuex';

export default {
  name: 'MiniSender',

  components: {
    MinimizeSenderButton,
  },

  emits: ['maximize-sender'],

  computed: {
    ...mapState('appPlayer', ['currentTitleInfo', 'deviceName', 'titleId']),

    /**
     * Determines background image URL of current content thumbnail
     * @returns {string}
     */
    backgroundImage () {
      switch (this.currentTitleInfo?.titleType) {
        case TITLE_TYPE.movie:
          if (this.currentTitleInfo.images?.length)
            return findWhere(this.currentTitleInfo.images, { type: 'TITLE_FEED_DETAILS_MOVIE' })?.url;
          return this.currentTitleInfo.imageDisplay;
        case TITLE_TYPE.episode:
          return findWhere(this.currentTitleInfo.images, { type: 'TITLE_FEED_DETAILS_EPISODE' })?.url;
        default:
          return '';
      }
    },

    /**
     * String of display pretty name of primary content
     * @returns {string}
     */
    primaryName () {
      if (this.currentTitleInfo.titleType === TITLE_TYPE.episode && this.currentTitleInfo.series) {
        return this.currentTitleInfo.series.seriesTitle || this.currentTitleInfo.series.name;
      } else {
        return this.currentTitleInfo.name || this.currentTitleInfo.title;
      }
    },

    /**
     * Formats pretty long name with full VOD metadata if such exists
     * @returns {string}
     */
    vodDisplayName () {
      if (this.currentTitleInfo?.titleType === TITLE_TYPE.episode && this.currentTitleInfo.series) {
        return `${this.primaryName} Season ${this.currentTitleInfo.series.seasonNum} Ep ${this.currentTitleInfo.series.episodeNum}: ${this.currentTitleInfo.name}`;
      }
      return this.primaryName;
    },
  },

  watch: {
    currentTitleInfo: {
      deep: true,
      handler (newValue) {
        if (newValue && newValue.id && String(newValue.id) !== String(this.titleId)) {
          this.$store.dispatch('appPlayer/newTitleId', newValue.id);
        }
      },
    },
  },

  methods: {
    onMiniSenderClick () {
      this.$emit('maximize-sender');
    },
  },
};
</script>

<style lang="scss" scoped>
  .mini-sender-container {
    align-items: center;
    background-color: $ds-black;
    border-top: 1px solid rgba($ds-medium_grey, 80%);
    bottom: 0;
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: 8px 0;
    position: fixed;
    width: 100%;
    z-index: 10000000000;
  }

  .cast-thumbnail-container {
    margin-right: 4px;
  }

  .cast-thumbnail {
    background-size: cover;
    height: 56px;
    width: 56px;
  }

  .mini-sender-text-content-container {
    margin-left: 4px;
    width: 300px;
  }

  .mini-sender-text-content {
    @extend %ds-caption-1;
    color: $ds-sho_neutral_light;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .casting-to {
    @extend %ds-body-bold;
    color: $ds-white;
  }
</style>
