const LegalPage = () => import('@pages/LegalPage.vue');

/**
 * Legal routes
 */
export default [
  /**
   * Legal routes
   */
  {
    path: '/eula/:subSection?',
    beforeEnter: () => {
      window.location.replace('https://www.viacomcbsprivacy.com/policy');
    },
  },
  {
    path: '/termsAndConditions/:subSection?',
    alias: '/tou',
    name: 'tou',
    component: LegalPage,
    props: (route) => ({ section: 'tou', subSection: route.params.subSection }),
  },
  {
    path: '/privacy',
    beforeEnter: () => {
      window.location.replace('https://www.viacomcbsprivacy.com/policy');
    },
  },
  {
    path: '/privacy/california',
    beforeEnter: () => {
      window.location.replace('https://www.viacomcbsprivacy.com/en/policy#California');
    },
  },
  {
    path: '/videoServicesPolicy/:subSection?',
    beforeEnter: () => {
      window.location.replace('https://www.viacomcbsprivacy.com/policy');
    },
  },
  {
    path: '/giftterms/:subSection?',
    name: 'gift',
    component: LegalPage,
    props: (route) => ({ section: 'gift', subSection: route.params.subSection }),
  },
];
