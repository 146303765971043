import api from '@api/api.js';
import Logger from '@utils/logger.js';
const logger = new Logger('title-detail');

const url = 'titles';

export const defaultValues = {
  advisories: [],
  bi: { siteSection: '', subSections: [] },
  countdownMillis: 0,
  created: new Date('1/1/19970'),
  creditMarkerMillis: 0,
  credits: [],
  description: { short: '' },
  downloadSupported: false,
  duration: 0,
  expirationDate: new Date('1/1/19970'),
  flags: [],
  id: 0,
  images: [],
  links: [],
  name: '',
  releaseYear: 19970,
  shortFormContent: null,
  social: { displayLike: false, displayFollow: false, facebook: '', instagram: '' },
  sortName: '',
  titleRating: '',
  titleRatingDisplayName: '',
  series: {},
  type: '',
  nextTitleId: 0,
  previousTitleId: 0,
  fetched: false,
  fetching: false,
};

export const state = {
  title: { ...defaultValues },
  error: null,
};

export const actions = {
  /**
   * Gets title detail data based on titleId
   * @param {number} titleId - titleId
   */
  async getTitleDetail ({ commit, rootState }, titleId) {
    const drm = rootState?.appPlayer?.drm?.uriRequestParam;
    const query = drm ? `${url}/${titleId}/format/${drm}` : `${url}/${titleId}`;

    try {
      commit('setFetching');
      const response = await api.get(query);
      commit('setTitleDetail', response);
    } catch (error) {
      logger.log(error);
      commit('setError', error);
    }
  },
  /**
   * Commits resetState mutation
   */
  resetState ({ commit }) {
    commit('resetState');
  },
};

export const mutations = {
  /**
   * Sets fetched title detail data in state
   */
  setTitleDetail (state, title) {
    state.title = { ...state.title, ...title }; // TODO: This shouldn't persist state during a title switch
    state.title.loaded = true;
    state.title.fetching = false;
  },
  /**
   * Sets fetching property in state
   */
  setFetching (state, fetching = true) {
    state.title.fetching = fetching;
  },
  /**
   * Sets error, fetched, and fetching properties in state
   */
  setError (state, error) {
    state.error = error;
    state.title.fetched = false;
    state.title.fetching = false;
  },
  /**
   * Resets state to defaults
   */
  resetState (state) {
    Object.assign(state.title, { ...defaultValues });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
